import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const ModalEmision = styled.div`
  background-color: #fff;
  padding: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  .poliza-icon {
    font-size: 64px;
    color: ${leerColor(colores.celeste)};
    margin: 0px 0 0px 0;
    border: 3px solid ${leerColor(colores.celeste)};
    border-radius: 100%;
    padding: 40px;
    display: flex;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    gap: 6px;

    span {
      color: ${leerColor(colores.grisEstados)};
    }
  }

  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    margin: 10px 0 10px 0;
  }

  .poliza-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  h4 {
    margin: 0;
  }

  /*
  h1 {
    margin-bottom: 11px;
    padding-bottom: 0;
  }
  h2 {
    margin-top: 30px;
  }
  */
  h3 {
    margin: 0;
    font-weight: 400;
  }
  .buttonsCont {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    width: 100%;
  }
`;

export const RechazoContainer = styled.div`
  background-color: ${({ theme }) => theme.background};
  box-sizing: border-box;
  width: 600px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.border}`};

  .header-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
  }
  textarea {
    margin: 0 !important;
  }
  p {
    margin: 0;
    color: ${({ theme }) => theme.subtitle};
  }
  .data-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-height: 380px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #e6e6e6;
      border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: ${leerColor(colores.gris)}; 
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${leerColor(colores.grisEstados)};
    }
  }

  .buttonsCont {
    display: flex;
    width: 100%;
    padding: 0 30px;
    gap: 10px;
  }
  a {
    width: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }

  .actionButtonsCont {
    display: flex;
    justify-content: flex-end;
  }
  .selectCont {
    width: 50%;
  }

  // *************************************************** //

  .fichaje {
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    justify-content: space-between;
    box-sizing: border-box;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    padding: 20px;
    border: ${({ theme }) => `1px solid ${theme.border}`};

    .icon {
      font-size: 20px;
      color: ${leerColor(colores.azulRebranding)};
      display: flex;
      align-items: center;
    }

    :hover {
      opacity: 0.7;
    }
  }

  .fichaje-data {
    display: flex;
    gap: 20px;
  }

  .fichaje-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    span {
      font-size: 14px;
      color: ${leerColor(colores.grisEstados)};
    }
    h4 {
      margin: 0;
      font-weight: 500;
      color: ${({ theme }) => theme.title};
    }
  }

  .fichaje-realizado {
    font-size: 16px;
    color: ${leerColor(colores.verde)};
    display: flex;
    gap: 10px;
    font-weight: 500;

    .icon-fichaje-realizado {
      display: flex;
      align-items: center;
      font-size: 18px;
    }
  }

  .disabled {
    pointer-events: none;
    color: ${leerColor(colores.grisEstados)};
    background-color: ${({ theme }) => theme.disabled};

    .icon {
      color: ${leerColor(colores.grisEstados)}

    }
  }

  .fichaje-disponible {
    font-size: 14px;
    font-weight: 500;
    color: ${leerColor(colores.azulRebranding)};
  }

  .icon-fichaje-disponible {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: ${leerColor(colores.azulRebranding)};
    }

  .selected {
    border: 1px solid ${leerColor(colores.azulRebranding)};
    background-color: ${({ theme }) => theme.selected_fichaje};
  }

  .fichado {
    pointer-events: none;
  }
`;