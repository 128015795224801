import { useState, useEffect } from "react";
import axiosRequest from "../../../../utils/axiosConfig";
import formatSelectData from "../../../../utils/formatSelectData";
import orderAlphabetically from "../../../../utils/alphabeticalOrder";
import dayjs from "dayjs";
import { useLoading } from "../../../../hooks/useLoading";
import isBetween from "dayjs/plugin/isBetween"
dayjs.extend(isBetween);

export const useFichajes = (mes, anio) => {
  // Fichajes
  const [fichajes, setFichajes] = useState({});
  const [fichajesDiaActual, setFichajesDiaActual] = useState([]);
  const loadingFichajes = useLoading();

  const [dia, setDia] = useState(new Date());
  const [calendario, setCalendario] = useState([]);

  const [usuario, setUsuario] = useState(
    JSON.parse(localStorage.getItem("userInfo"))?.id_usuario
  );
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosData, setUsuariosData] = useState([]);

  const [estadisticasMensuales, setEstadisticasMensuales] = useState({});
  const [estadisticasAnuales, setEstadisticasAnuales] = useState({});
  const [mesEstadisticasMensuales, setMesEstadisticasMensuales] = useState(
    new Date().getMonth()
  );

  const [excepciones, setExcepciones] = useState([]);

  useEffect(() => {
    const getCalendarDays = async () => {
      setCalendario([]);
      const daysInMonth = new Date(anio, parseInt(mes) + 1, 0).getDate();
      const mesFormatted = dayjs(new Date(anio, mes, 1)).format("MM");
      const excepciones = await axiosRequest.get(
        `/excepciones_mes/${usuario}/${mesFormatted}/${anio}`
      );
      const calendarDays = [...Array(daysInMonth)].map((_, index) => {
        const excepcion = excepciones.data.filter((item) => {
          if (item.periodo_excepcion === "Fecha") {
            if (
              index + 1 ===
              parseInt(dayjs(item.periodo_fecha_excepcion).format("DD"))
            ) {
              return item;
            }
          }
          if (item.periodo_excepcion === "Día") {
            const currentDate = dayjs(
              new Date(anio, parseInt(mes), index + 1)
            ).format("YYYY-MM-DD");
            const desdeDate = dayjs(item.periodo_fecha_desde_excepcion).format(
              "YYYY-MM-DD"
            );
            const hastaDate = dayjs(item.periodo_fecha_hasta_excepcion).format(
              "YYYY-MM-DD"
            );
            const isInBetween = dayjs(currentDate).isBetween(
              desdeDate,
              hastaDate,
              "day",
              "[]"
            );
            const currentDateDay = new Date(
              anio,
              parseInt(mes),
              index + 1
            ).getDay();
            if (
              parseInt(item.periodo_dia_excepcion) === currentDateDay &&
              isInBetween
            ) {
              return item;
            }
          }
          if (item.periodo_excepcion === "Desde / Hasta") {
            const currentDate = dayjs(
              new Date(anio, parseInt(mes), index + 1)
            ).format("YYYY-MM-DD");
            const desdeDate = dayjs(item.periodo_fecha_desde_excepcion).format(
              "YYYY-MM-DD"
            );
            const hastaDate = dayjs(item.periodo_fecha_hasta_excepcion).format(
              "YYYY-MM-DD"
            );
            const isInBetween = dayjs(currentDate).isBetween(
              desdeDate,
              hastaDate,
              "day",
              "[]"
            );
            if (isInBetween) return item;
          }
        });
        if (excepcion.length > 0) {
          return { dia: index + 1, excepcion: excepcion };
        }
        return { dia: index + 1, excepcion: [] };
      });
      setCalendario(calendarDays);
    };
    getCalendarDays();
  }, [mes, usuario, anio]);

  useEffect(() => {
    const getUsuarios = async () => {
      try {
        const usuarios = await axiosRequest.get("/operadores");
        const filterUsuarios = usuarios.data.filter(
          (usuario) => !usuario.nombre_operador.toLowerCase().includes("prueba")
        );
        const formatUsuarios = formatSelectData(
          filterUsuarios,
          "id_usuario",
          ["nombre_operador", "apellido_operador"],
          "id_usuario"
        );
        setUsuariosData(filterUsuarios);
        setUsuarios(formatUsuarios.sort(orderAlphabetically));
      } catch (error) {
        console.log(error);
      }
    };
    getUsuarios();
  }, []);

  const getFichajeDiaActual = async () => {
    try {
      const fichajes = await axiosRequest.get(
        `/fichajes_usuario/${usuario}/${dayjs(dia).format("YYYYMMDD")}`
      );
      setFichajesDiaActual(fichajes.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFichajeDiaActual();
  }, []);

  const handleDiaFichaje = async (daySelected) => {
    if (usuario && calendario) {
      const diaSeleccionado = daySelected
        ? new Date(anio, mes, daySelected)
        : dia;
      if (daySelected) {
        setDia(new Date(anio, mes, daySelected));
      }
      loadingFichajes.setLoading(true);
      const fecha = dayjs(diaSeleccionado).format("YYYYMMDD");
      try {
        const fichajes = await axiosRequest.get(
          `/fichajes_usuario/${usuario}/${fecha}`
        );
        const findDayCalendar = calendario.find(
          (item) => item.dia === parseInt(dayjs(fecha).format("DD"))
        );
        setFichajes({
          fichajes: fichajes.data,
          excepcion: findDayCalendar.excepcion,
        });
        loadingFichajes.setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    handleDiaFichaje();
  }, [usuario, calendario]);

  const getEstadisticasUsuarioMes = async () => {
    setEstadisticasMensuales({});
    try {
      const estadisticas = await axiosRequest.get(
        `/fichajes_usuario_estadisticas/${usuario}/${mesEstadisticasMensuales}/${anio}`
      );
      setEstadisticasMensuales(estadisticas.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEstadisticasUsuarioMes();
  }, [mesEstadisticasMensuales, anio, usuario]);

  useEffect(() => {
    const getEstadisticasUsuarioAnual = async () => {
      try {
        const estadisticas = await axiosRequest.get(
          `/fichajes_usuario_anual/${usuario}/${anio}`
        );
        const data = {
          labels: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ],
          datasets: [
            {
              label: "Horas esperadas",
              data: estadisticas.data.map((item) => item.horas_esperadas),
              backgroundColor: "#c4d4ff",
            },
            {
              label: "Horas cumplidas",
              data: estadisticas.data.map((item) => item.horas_cumplidas),
              backgroundColor: "#1A56FF",
            },
          ],
        };

        setEstadisticasAnuales(data);
      } catch (error) {
        console.log(error);
      }
    };
    getEstadisticasUsuarioAnual();
  }, [usuario, anio]);

  useEffect(() => {
    const getExcepciones = async () => {
      try {
        const excepciones = await axiosRequest.get(
          `/excepciones/${usuario}/${anio}`
        );
        setExcepciones(excepciones.data);
      } catch (error) {
        console.log(error);
      }
    };
    getExcepciones();
  }, [usuario, anio]);

  const refreshFichajes = () => {
    getFichajeDiaActual();
    handleDiaFichaje();
    getEstadisticasUsuarioMes();
  };

  const handleChangeUsuario = (usuario) => setUsuario(usuario);
  const handleChangeMesEstadisticas = (mes) => setMesEstadisticasMensuales(mes);

  return {
    data: {
      usuario,
      usuarios,
      usuariosData,
      fichajesDiaActual,
      fichajes,
      calendario,
      excepciones,
      estadisticasAnuales,
      estadisticasMensuales,
      mesEstadisticasMensuales,
      dia,
      loadingFichajes,
    },
    methods: {
      refreshFichajes,
      handleDiaFichaje,
      handleChangeUsuario,
      handleChangeMesEstadisticas,
    },
  };
};
