import React, { useEffect, useState } from "react";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import TitlePageSection from "../../../components/TitlePageSection";
import Sidebar from "../../../components/Sidebar";
import { Container } from "./styled";
import axiosRequest from "../../../utils/axiosConfig";
import { Col, Grid } from "../../../components/Grid";
import { useParams, useLocation } from "react-router-dom";
import { useTabs } from "../../../hooks/useTabs";
import NewTabs from "../../../components/NewTabs";
import NewInputSelect from "../../../components/NewInputSelect";
import { FiArrowLeft } from "react-icons/fi";
import { anios, meses } from "../../../constants/data";
import NewButton from "../../../components/NewButton";
import useModal from "../../../hooks/useModal";
import ModalObjetivos from "../../../components/Modals/Vendedor/Objetivos";
import { useLoading } from "../../../hooks/useLoading";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import VendedorEdicionPremiosTab from "../../../components/Tabs/Vendedor/EdicionPremios";
import VendedorRendimientoDiarioTab from "../../../components/Tabs/Vendedor/RendimientoDiario";
import VendedorRendimientoAnualTab from "../../../components/Tabs/Vendedor/RendimientoAnual";
import VendedorOperacionesTab from "../../../components/Tabs/Vendedor/Operaciones";
import VendedorDetalleTab from "../../../components/Tabs/Vendedor/Detalle";
import { VENDEDOR_TABS } from "../../../constants/tabs";
import BackButton from "../../../components/BackButton";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import formatSelectData from "../../../utils/formatSelectData";

const Vendedor = () => {
  const vendedor = useParams();
  const location = useLocation();
  const mesActual = new Date().getMonth();
  const userNombre = JSON.parse(
    localStorage.getItem("userInfo")
  )?.nombre_operador;
  const userRol = JSON.parse(localStorage.getItem("userInfo"))?.id_rol;

  const vendedorNombre = location.state?.nombre
    ? location.state.nombre
    : userNombre;
  const [premios, setPremios] = useState([]);
  const [mes, setMes] = useState(
    location.state?.mes ? location.state.mes : new Date().getMonth()
  );
  const [anio, setAnio] = useState(
    location.state?.anio ? location.state.anio : new Date().getFullYear()
  );
  const [objetivos, setObjetivos] = useState(null);
  const [data, setData] = useState(null);
  const [limites, setLimites] = useState(null);
  const [productos, setProductos] = useState([]);
  const [productoSeleccionado, setProductoSeleccionado] = useState(1);

  const { loading, setLoading } = useLoading();
  const { handleTab, tabSelected } = useTabs();
  const objetivosModal = useModal();

  useEffect(() => {
    const getProductos = async () => {
      try {
        const productos = await axiosRequest.get("/premios/productos");
        const productosList = formatSelectData(
          productos.data,
          "numero",
          "descripcion",
          "numero"
        );
        setProductos(productosList);
      } catch (error) {
        console.log(error);
      }
    };
    getProductos();
  }, []);

  useEffect(() => {
    const getVendedor = async () => {
      if (!location.state) {
        try {
          setPremios([]);
          const premios = await axiosRequest.get(
            `/premios/vendedor/${vendedor.id}/${mes}/${anio}`
          );
          setPremios(premios.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getVendedor();
  }, []);

  useEffect(() => {
    const getVendedor = async () => {
      if (vendedor && mes !== null && anio) {
        try {
          setLoading(true);
          setObjetivos(null);
          const data = await axiosRequest.get(
            `/premios/vendedor/${vendedor.id}/${mes}/${anio}`
          );
          const objetivos = await axiosRequest.get(
            `/premios/vendedor/objetivos/${vendedor.id}/${mes}/${anio}`
          );
          const vendedorData = await axiosRequest.get(
            `/premios/vendedordata/${vendedor.id}/${mes}/${anio}`
          );
          setPremios(data.data);
          setObjetivos(objetivos.data);
          setData(vendedorData.data);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    };
    getVendedor();
  }, [vendedor, mes, anio]);

  const refreshData = async () => {
    if (vendedor) {
      try {
        setObjetivos(null);
        setLoading(true);
        const objetivos = await axiosRequest.get(
          `/premios/vendedor/objetivos/${vendedor.id}/${mesActual}/${anio}`
        );
        setObjetivos(objetivos.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  let tabs = [];
  if (userRol === 10 || userRol === 18 || userRol === 22) {
    const filter = VENDEDOR_TABS.filter(
      (item) => item.name !== "Edición de premios"
    );
    tabs = filter;
  } else {
    tabs = VENDEDOR_TABS;
  }

  return (
    <div>
      <div>
        {objetivosModal.isModalOpen ? (
          <ModalObjetivos
            modal={objetivosModal}
            vendedor={location.state.data}
            objetivos={objetivos}
            refreshData={refreshData}
          />
        ) : null}
        {/* <Sidebar /> */}
        <Layout>
          <NewWrapper>
            <NewInner>
              <Container>
                <div>
                  {userRol !== 10 && userRol !== 18 && userRol !== 22 ? (
                    <BackButton url={location.state?.previousUrl ?? "/Premios"} text={"lista de vendedores"}/>
                  ) : null}
                  <NewTitlePageSection
                    title={vendedorNombre}
                    description="Lista de premios ganados y estadísticas de rendimiento general"
                  >
                    {userRol !== 10 && userRol !== 18 && userRol !== 22 ? (
                      <NewButton
                        backgroundColor={true}
                        onClick={() => objetivosModal.setIsModalOpen(true)}
                      >
                        Actualizar objetivos
                      </NewButton>
                    ) : null}
                  </NewTitlePageSection>
                </div>
                <NewTabs
                  tabs={tabs}
                  tabSelected={tabSelected}
                  handleTab={handleTab}
                />

                  <Grid colGap={21} rowGap={21} narrow={false}>
                  {tabSelected !== 2 ? 

                    <Col desktop={2}>
                      <NewInputSelect
                        name={"mes"}
                        labelName={"Mes"}
                        onChange={(e) => setMes(e.target.value)}
                        placeholder={"Mes"}
                        options={meses}
                        value={mes}
                      />
                    </Col>
                    : null}
                    <Col desktop={2}>
                      <NewInputSelect
                        name={"anio"}
                        labelName={"Año"}
                        onChange={(e) => setAnio(e.target.value)}
                        placeholder={"Año"}
                        options={anios}
                        value={anio}
                      />
                    </Col>
                    {tabSelected === 3 ? (
                      <Col desktop={2}>
                        <NewInputSelect
                          name={"producto"}
                          labelName={"Producto"}
                          onChange={(e) =>
                            setProductoSeleccionado(e.target.value)
                          }
                          placeholder={"Producto"}
                          options={productos}
                          value={productoSeleccionado}
                        />
                      </Col>
                    ) : null}
                  </Grid>

                {tabSelected === 0 ? (
                  <VendedorDetalleTab
                    detalle={{
                      anio,
                      mes,
                      vendedor,
                      premios,
                      vendedorNombre,
                      loading,
                      objetivos,
                      objetivosModal,
                      data,
                      location,
                      previousUrl: location.state?.previousUrl,
                    }}
                  />
                ) : null}

                {tabSelected === 1 ? (
                  <VendedorOperacionesTab data={{ vendedor, mes, anio, loading }} />
                ) : null}

                {tabSelected === 2 ? (
                  <VendedorRendimientoAnualTab
                    data={{ vendedorNombre, vendedor, mes, anio, setLoading }}
                  />
                ) : null}

                {tabSelected === 3 ? (
                  <VendedorRendimientoDiarioTab
                    data={{ vendedor, mes, anio, productos, productoSeleccionado }}
                  />
                ) : null}

                {tabSelected === 4 ? (
                  <VendedorEdicionPremiosTab
                    data={{ premios, vendedor, vendedorNombre, mes, anio, loading }}
                  />
                ) : null}
              </Container>
            </NewInner>
          </NewWrapper>
        </Layout>
      </div>
    </div>
  );
};

export default Vendedor;
