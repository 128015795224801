import React from "react";
import { Container } from "./styled";
import { FiArrowRight, FiGlobe } from "react-icons/fi";
import { LOGOS_COMPANIAS } from "../../constants/logos-companias";
import Estado from "../Estado";
import { HiSparkles } from "react-icons/hi";
import { DEFAULT_AVATAR } from "../../constants/data";

const HeaderTitleSection = ({ compania, vendedor, estado, estado_previo, prioridad, operadorData, vendedorData, arrastre, asignado }) => {
  const logo = LOGOS_COMPANIAS.find(
    (item) => item.nombre.toLowerCase() === compania?.toLowerCase()
  )?.url;

  console.log(operadorData)
  console.log(vendedorData)

  return (
    <Container>
      <div className="header-section">
        {/* <img src={logo} className="logo-compania" alt="logo de la compañia" /> */}
        {vendedor === 192 ?
        <span className="header-info autogestion">
          <span>
            <FiGlobe />
          </span>{" "}
          Autogestión
        </span>
        : null}
        {prioridad ?
        <span className="header-info autogestion">
          <span>
            <HiSparkles />
          </span>{" "}
          Prioridad
        </span>
        : null}
        {arrastre ?
        <span className="header-info autogestion">
          <span>
            <FiArrowRight />
          </span>{" "}
          Arrastre
        </span>
        : null}
        <span className="estado-info">
          Estado actual:  <Estado estado={estado} />
        </span>
        {estado_previo ?
        <span className="estado-info">
          Estado previo:  <Estado estado={estado_previo} />
        </span> : null}
        {vendedorData ?
        <div className="vendedor-info">
          <span>Vendedor:</span>
          <img src={vendedorData.avatar_usuario ?? DEFAULT_AVATAR} alt={`Foto de perfil de ${vendedorData.nombre_operador} ${vendedorData.apellido_operador}`} />
          <span>{vendedorData.nombre_operador} {vendedorData.apellido_operador}</span>
        </div>
        : null}
        {operadorData ?
        <div className="vendedor-info">
          <span>Operador:</span>
          <img src={operadorData.avatar_usuario ?? DEFAULT_AVATAR} alt={`Foto de perfil de ${operadorData.nombre_operador} ${operadorData.apellido_operador}`} />
          <span>{operadorData.nombre_operador} {operadorData.apellido_operador}</span>
        </div>
        : null}
        {asignado ?
        <div className="vendedor-info">
        <span>Asignado a:</span>
        <img src={DEFAULT_AVATAR} alt={`Foto de usuario`} />
        <span>{asignado}</span>
        </div>
        : null}
      </div>
    </Container>
  );
};

export default HeaderTitleSection;
