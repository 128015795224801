import React from 'react'
import Divider from '../Divider'
import { Col, Grid } from '../Grid'
import Subtitle from '../Subtitle'
import { FiUser } from 'react-icons/fi'
import NewInputSelect from '../NewInputSelect'

const UsuarioFichajes = ({ data }) => {
  const { usuario, usuarios, handleChangeUsuario } = data
  
  return (
    <>
    <Divider />

    <Grid colGap={21} rowGap={21} narrow={false}>
    <Col desktop={9}>
        <Subtitle
          title="Usuario"
          description="Elegí un usuario para ver sus fichajes y estadísticas"
          icon={<FiUser />}
          disableDivider={true}
        />
      </Col> 
      <Col desktop={3}>
        <NewInputSelect
          name={"usuario"}
          onChange={(e) => handleChangeUsuario(e.target.value)}
          placeholder={"Usuario"}
          options={usuarios}
          value={usuario}
        />
      </Col>
    </Grid>
    </>
  )
}

export default UsuarioFichajes