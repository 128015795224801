import React from "react";
import { Col, Grid } from "../../../Grid";
import NewInputSelect from "../../../NewInputSelect";
import { anios, meses } from "../../../../constants/data";
import NewTable from "../../../NewTable";
import { objetivosVendedores } from "../../../../utils/tableData";
import { useTable } from "../../../../hooks/useTable";
import useModal from "../../../../hooks/useModal";
import ModalConversorPrima from "../../../Modals/Premios/ModalConversorPrima";
import NewButton from "../../../NewButton";
import { Container } from "./styled";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const PremiosObjetivosTab = ({ data }) => {
  const { mes, setMes, objetivos, loadingObjetivos, anio, setAnio } = data;
  const location = useLocation()
  const tableData = objetivosVendedores(location);
  const { order, handleTableOrder } = useTable();
  const conversorPrimaModal = useModal();

  return (
    <>
      <Container>
      {conversorPrimaModal.isModalOpen ? <ModalConversorPrima modal={conversorPrimaModal} /> : null}
      <Grid colGap={60} rowGap={21} narrow={false}>
        <Col desktop={2}>
          <NewInputSelect
            name={"mes"}
            labelName={"Mes"}
            onChange={(e) => setMes(e.target.value)}
            placeholder={"Mes"}
            options={meses}
            value={mes}
          />
        </Col>
        <Col desktop={2}>
          <NewInputSelect
            name={"anio"}
            labelName={"Año"}
            onChange={(e) => setAnio(e.target.value)}
            placeholder={"Año"}
            options={anios}
            value={anio}
          />
        </Col>
      </Grid>
        
        <NewButton
          backgroundColor={true}
          onClick={conversorPrimaModal.handleModal}
        >
          Conversor prima
        </NewButton>
      </Container>
      <NewTable
        columns={tableData}
        data={objetivos}
        loading={loadingObjetivos}
        order={handleTableOrder}
        orderParams={order}
      />
    </>
  );
};

export default PremiosObjetivosTab;
