import {
  Link,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Container } from "./styled";
import { FiBarChart, FiTarget, FiUser } from "react-icons/fi";
import NewButton from "../../../components/NewButton";
import Subtitle from "../../../components/Subtitle";
import { Col, Grid } from "../../../components/Grid";
import LineChart from "../../../components/LineChart";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import useModal from "../../../hooks/useModal";
import axiosRequest from "../../../utils/axiosConfig";
import Divider from "../../../components/Divider";
import BackButton from "../../../components/BackButton";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import { useTheme } from "../../../context/ThemeContext";

const GrupoVendedores = () => {
  const location = useLocation();
  const [vendedorSeleccionado, setVendedorSeleccionado] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const formatter = new Intl.NumberFormat("es-ES", {});
  const { theme } = useTheme()

  useEffect(() => {
    const getData = async () => {
      if (location?.state) {
        const integrantes = location.state.integrantes;
        const data = await axiosRequest.post(
          "/premios/grupo/integrantes",
          integrantes
        );
        setCsvData(data.data);
      }
    };
    getData();
  }, [location]);

  const labels = [
    "01/07",
    "02/07",
    "03/07",
    "04/07",
    "05/07",
    "06/07",
    "07/07",
    "08/07",
    "09/07",
    "10/07",
    "11/07",
    "12/07",
    "13/07",
    "14/07",
    "15/07",
    "16/07",
    "17/07",
    "18/07",
    "19/07",
    "20/07",
    "21/07",
    "22/07",
    "23/07",
    "24/07",
    "25/07",
    "26/07",
    "27/07",
    "28/07",
    "29/07",
    "30/07",
    "31/07",
  ];
  const days = labels.map((label, index) => {
    const find = location.state.integrantes[
      vendedorSeleccionado
    ].operacionesPrimaPorDia.find((item) => {
      return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
    });
    if (find) {
      return find.operaciones;
    } else {
      return 0;
    }
  });
  const prima = labels.map((label, index) => {
    const find = location.state.integrantes[
      vendedorSeleccionado
    ].operacionesPrimaPorDia.find((item) => {
      return parseInt(dayjs(item.fechaucac).format("D")) === index + 1;
    });
    if (find) {
      return find.prima;
    } else {
      return 0;
    }
  });

  const operacionesContratado = labels.map((label, index) => {
    const find = location.state.integrantes[
      vendedorSeleccionado
    ].operacionesPrimaPorDiaContratado.find((item) => {
      return parseInt(dayjs(item.fechauc).format("D")) === index + 1;
    });
    if (find) {
      return find.operaciones;
    } else {
      return 0;
    }
  });

  const primaContratado = labels.map((label, index) => {
    const find = location.state.integrantes[
      vendedorSeleccionado
    ].operacionesPrimaPorDiaContratado.find((item) => {
      return parseInt(dayjs(item.fechauc).format("D")) === index + 1;
    });
    if (find) {
      return find.prima;
    } else {
      return 0;
    }
  });

  const objetivoOperacionesPorDia = labels.map((label, index) => {
    return location.state.integrantes[vendedorSeleccionado]
      .objetivoOperacionesPorDia;
  });

  const objetivoPrimaPorDia = labels.map((label, index) => {
    return location.state.integrantes[vendedorSeleccionado].objetivoPrimaPorDia;
  });

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Operaciones emitidas por día",
      },
    },
  };

  const options2 = {
    responsive: true,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Prima emitida por día",
      },
    },
  };

  const optionsOperacionesContratadas = {
    responsive: true,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Operaciones contratadas por día",
      },
    },
  };

  const optionsPrimaContratada = {
    responsive: true,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Prima contratada por día",
      },
    },
  };

  const dataPrimaContratado = {
    labels: labels,
    datasets: [
      {
        label: "Prima contratada",
        data: primaContratado,
        fill: false,
        borderColor: "rgb(26, 86, 255)",
        tension: 0.001,
      },
      {
        label: "Prima esperada",
        data: objetivoPrimaPorDia,
        fill: false,
        borderColor: "rgb(210, 27, 118)",
        tension: 0.001,
      },
    ],
  };

  const dataOperacionesContratado = {
    labels: labels,
    datasets: [
      {
        label: "Operaciones contratadas",
        data: operacionesContratado,
        fill: false,
        borderColor: "rgb(26, 86, 255)",
        tension: 0.001,
      },
      {
        label: "Operaciones esperadas",
        data: objetivoOperacionesPorDia,
        fill: false,
        borderColor: "rgb(210, 27, 118)",
        tension: 0.001,
      },
    ],
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Operaciones por día",
        data: days,
        fill: false,
        borderColor: "rgb(26, 86, 255)",
        tension: 0.001,
      },
      {
        label: "Operaciones esperadas",
        data: objetivoOperacionesPorDia,
        fill: false,
        borderColor: "rgb(210, 27, 118)",
        tension: 0.001,
      },
    ],
  };

  const data2 = {
    labels: labels,
    datasets: [
      {
        label: "Prima por día",
        data: prima,
        fill: false,
        borderColor: "rgb(26, 86, 255)",
        tension: 0.001,
      },
      {
        label: "Prima esperada",
        data: objetivoPrimaPorDia,
        fill: false,
        borderColor: "rgb(210, 27, 118)",
        tension: 0.001,
      },
    ],
  };

  const optionsBar = {
    responsive: true,
    indexAxis: "y",
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Visualización de primas realizadas a lo largo del año",
      },
    },
  };

  const labelsBar = location.state.integrantes.map((item) => item.nombre);
  const dataBar = {
    labels: labelsBar,
    datasets: [
      {
        label: "Prima obtenida",
        data: location.state.integrantes.map((item) => Math.round(item.prima)),
        backgroundColor: "#1A56FF",
      },
    ],
  };

  return (
    <div>
      <div>
        <Layout>
          <NewWrapper>
            <NewInner>
              <Container>
                <div>
                  <BackButton url={location.previousUrl ?? "/Premios"} text={"lista de vendedores"}/>
                  <NewTitlePageSection
                    title={`${location.state.nombre}`}
                    description="Modificá los valores de los premios del vendedor"
                  >
                  </NewTitlePageSection>
                </div>

                <Divider />

                <Subtitle
                  icon={<FiUser />}
                  title={"Integrantes del grupo"}
                  description={"Seleccioná un vendedor para ver sus estadísticas"}
                  disableDivider={true}
                />
                <Grid colGap={21} rowGap={21} narrow={false}>
                  {location.state.integrantes.length > 0
                    ? location.state.integrantes.map((vendedor, index) => {
                        return (
                          <Col desktop={3}>
                            <div
                              className={`vendedor-container ${
                                vendedorSeleccionado !== index &&
                                "no-seleccionado"
                              }`}
                              onClick={() => setVendedorSeleccionado(index)}
                            >
                              <h2>#{index + 1}</h2>
                              <span className="vendedor-nombre">
                                {vendedor.nombre}
                              </span>
                              <span>${formatter.format(vendedor.prima)}</span>
                            </div>
                          </Col>
                        );
                      })
                    : null}
                  <Col desktop={12}>
                    <Link
                      to={{
                        pathname: `/Premios/EditarGrupo/${location.state.id_vendedores_grupo}`,
                        state: location.state,
                      }}
                    >
                      {" "}
                      <NewButton backgroundColor={true}>Editar grupo</NewButton>
                    </Link>
                  </Col>
                </Grid>
                <Divider />
                <Subtitle
                  icon={<FiTarget />}
                  title={`Objetivos de ${location.state.integrantes[vendedorSeleccionado].nombre}`}
                  description={"Prima y operaciones actuales y esperadas"}
                  disableDivider={true}
                />
                <Grid colGap={21} rowGap={21} narrow={false}>
                  <Col desktop={3}>
                    <div className={`premio-container`}>
                      <span>Prima actual</span>
                      <h2>
                        $
                        {formatter.format(
                          location.state.integrantes[vendedorSeleccionado].prima
                        )}
                      </h2>
                    </div>
                  </Col>
                  <Col desktop={3}>
                    <div className={`premio-container`}>
                      <span>Objetivo Prima</span>
                      <h2>
                        $
                        {formatter.format(
                          location.state.integrantes[vendedorSeleccionado]
                            .objetivo_prima
                        )}
                      </h2>
                    </div>
                  </Col>
                  <Col desktop={3}>
                    <div className={`premio-container`}>
                      <span>Operaciones actuales</span>
                      <h2>
                        {
                          location.state.integrantes[vendedorSeleccionado]
                            .operaciones
                        }
                      </h2>
                    </div>
                  </Col>
                  <Col desktop={3}>
                    <div className={`premio-container`}>
                      <span>Objetivo operaciones</span>
                      <h2>
                        {
                          location.state.integrantes[vendedorSeleccionado]
                            .objetivo_operaciones
                        }
                      </h2>
                    </div>
                  </Col>
                </Grid>

                <Divider />

                <Subtitle
                  icon={<FiBarChart />}
                  title={"Estadísticas por día"}
                  description={
                    "Prima y operaciones por día durante el mes actual"
                  }
                  disableDivider={true}
                />

                <Grid colGap={21} rowGap={21} narrow={false}>
                  <Col desktop={12}>
                    <LineChart data={data} options={{
                      responsive: true,
                      scales: {
                        x: {
                          stacked: false,
                          ticks: {
                            color: theme === "dark" ? "#ffffff" : '#666666',
                          },
                          grid: {
                            borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
                            tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
                            color: theme === "dark" ? "#243158" : '#e5e5e5',
                          }
                        },
                        y: {
                          stacked: false,
                          ticks: {
                            color: theme === "dark" ? "#ffffff" : '#666666',
                          },
                          grid: {
                            borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
                            tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
                            color: theme === "dark" ? "#243158" : '#e5e5e5',
                          }
                        },
                      },
                      plugins: {
                        title: {
                          display: true,
                          text: "Prima diaria",
                        },
                      },
                    }} />
                  </Col>
                  <Col desktop={12}>
                    <LineChart data={data2} options={{
                      responsive: true,
                      scales: {
                        x: {
                          stacked: false,
                          ticks: {
                            color: theme === "dark" ? "#ffffff" : '#666666',
                          },
                          grid: {
                            borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
                            tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
                            color: theme === "dark" ? "#243158" : '#e5e5e5',
                          }
                        },
                        y: {
                          stacked: false,
                          ticks: {
                            color: theme === "dark" ? "#ffffff" : '#666666',
                          },
                          grid: {
                            borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
                            tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
                            color: theme === "dark" ? "#243158" : '#e5e5e5',
                          }
                        },
                      },
                      plugins: {
                        title: {
                          display: true,
                          text: "Prima diaria",
                        },
                      },
                    }} />
                  </Col>
                  <Col desktop={12}>
                    <LineChart
                      data={dataPrimaContratado}
                      options={{
                        responsive: true,
                        scales: {
                          x: {
                            stacked: false,
                            ticks: {
                              color: theme === "dark" ? "#ffffff" : '#666666',
                            },
                            grid: {
                              borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
                              tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
                              color: theme === "dark" ? "#243158" : '#e5e5e5',
                            }
                          },
                          y: {
                            stacked: false,
                            ticks: {
                              color: theme === "dark" ? "#ffffff" : '#666666',
                            },
                            grid: {
                              borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
                              tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
                              color: theme === "dark" ? "#243158" : '#e5e5e5',
                            }
                          },
                        },
                        plugins: {
                          title: {
                            display: true,
                            text: "Prima diaria",
                          },
                        },
                      }}
                    />
                  </Col>
                  <Col desktop={12}>
                    <LineChart
                      data={dataOperacionesContratado}
                      options={{
                        responsive: true,
                        scales: {
                          x: {
                            stacked: false,
                            ticks: {
                              color: theme === "dark" ? "#ffffff" : '#666666',
                            },
                            grid: {
                              borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
                              tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
                              color: theme === "dark" ? "#243158" : '#e5e5e5',
                            }
                          },
                          y: {
                            stacked: false,
                            ticks: {
                              color: theme === "dark" ? "#ffffff" : '#666666',
                            },
                            grid: {
                              borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
                              tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
                              color: theme === "dark" ? "#243158" : '#e5e5e5',
                            }
                          },
                        },
                        plugins: {
                          title: {
                            display: true,
                            text: "Prima diaria",
                          },
                        },
                      }}
                    />
                  </Col>
                </Grid>
              </Container>
            </NewInner>
          </NewWrapper>
        </Layout>
      </div>
    </div>
  );
};

export default GrupoVendedores;
