import { Container } from "./styled";
import { FiArrowLeft } from "react-icons/fi";
import Divider from "../../../components/Divider";
import NewButton from "../../../components/NewButton";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import BackButton from "../../../components/BackButton";
import Layout from "../../../components/Layout";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import Success from "../../../components/Success";
import TipoExcepcion from "./components/TipoExcepcion";
import PeriodoExcepcion from "./components/PeriodoExcepcion";
import HorarioExcepcion from "./components/HorarioExcepcion";
import PreviewExcepcion from "./components/PreviewExcepcion";
import { useExcepcion } from "./hooks/useExcepcion";
import UsuarioExcepcion from "./components/UsuarioExcepcion";

const CrearExcepcion = () => {

 const { data, form } = useExcepcion()
 const { inputValues, handleChange, handleChangeExternal, handleChangeCheckbox, formErrors, handleValidation, handleSubmit } = form
 const { usuarios, tiposGoceSueldo, success } = data

  return (
    <div>
      {/* <Sidebar /> */}
      <Layout>
        <NewWrapper>
          <NewInner>
            <Container>
              <div>
                <BackButton url={"/Fichajes"} text="Fichajes" />
                <NewTitlePageSection
                  title="Crear Excepción"
                  description="Agregá excepciones para los fichajes de los usuarios"
                />
              </div>
              <Divider />
              {!success ? (
                <>
                  <UsuarioExcepcion data={{ usuarios }} form={{ inputValues, handleChange, formErrors }}  />
                  <Divider />
                  <TipoExcepcion data={{ tiposGoceSueldo }} form={{ inputValues, handleChange, handleChangeExternal, handleChangeCheckbox, formErrors }} />
                  <Divider />
                  <PeriodoExcepcion form={{ inputValues, handleChange, formErrors }} />      
                  <Divider />
                  <HorarioExcepcion form={{ inputValues, handleChange, formErrors }} />
                  <Divider />
                  <PreviewExcepcion form={{ inputValues }} />
                  <Divider />
                  <div className="buttons-container">
                    <NewButton
                      backgroundColor={true}
                      onClick={handleValidation}
                    >
                      Crear Excepción
                    </NewButton>
                    <Link to="/Fichajes">
                      <NewButton borderColor={true} textColor={true}>
                        <FiArrowLeft /> Volver a Fichajes
                      </NewButton>
                    </Link>
                  </div>
                </>
              ) : (
                <Success
                  title="¡Excepción creada!"
                  description={`La nueva excepción fue cargada correctamente`}
                  link={"/Fichajes"}
                  page="Fichajes"
                />
              )}
            </Container>
          </NewInner>
        </NewWrapper>
      </Layout>
    </div>
  );
};

export default CrearExcepcion;
