import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../../constants";

export const RechazoContainer = styled.div`
  background-color: ${({ theme }) => theme.background};
  box-sizing: border-box;
  width: 600px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border};
  .motivos-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    label {
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      color: ${({ theme }) => theme.title};
      font-weight: 500;
    }
    .motivo {
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};
        border: 1px solid #eaeaea;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 20px;
        width: 100%;
        cursor: pointer;
        //transition: 0.2s;
    }

    .motivo-selected {
        color: ${leerColor(colores.azulRebranding)};
        border: 1px solid ${leerColor(colores.azulRebranding)};
        font-weight: 500;
    }

    .motivo:hover {
        opacity: 0.8s;
    }
  }
  .header-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    //margin: 20px 0 20px 0;
  }
  textarea {
    margin: 0 !important;
  }
  p {
    margin: 0;
    color: ${leerColor(colores.grisEstados)};
  }
  .data-container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .buttonsCont {
    display: flex;
    width: 100%;
    padding: 0 30px;
    gap: 10px;
  }
  a {
    width: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }
  /*
  .buttonsCont a {
    margin-left: 32px;
  }
  */
  .actionButtonsCont {
    display: flex;
    justify-content: flex-end;
    //margin-top: 43px;
  }
  .selectCont {
    width: 50%;
  }
  .csv-link {
    text-decoration: none;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    color: ${({ theme }) => theme.subtitle};
    font-weight: 300;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px;
    gap: 10px;
    cursor: pointer;

    .csv-link-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .csv-info-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .info-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
    }

    .csv-link-title {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      padding: 0;
      color: ${({ theme }) => theme.title};
    }

    .icon {
      color: ${leerColor(colores.azulRebranding)};
      display: flex;
      font-size: 24px;
    }

    .icon-file {
      color: ${leerColor(colores.azulRebranding)};
      display: flex;
      font-size: 24px;
      border: 1px solid ${({ theme }) => theme.border};
      border-radius: 8px;
      box-sizing: border-box;
      padding: 10px;
    }

    :hover {
      border: 1px solid ${leerColor(colores.azulRebranding)};
      transition: 0.3s;
    }
  }
`;
