import {
  FiCalendar,
  FiCoffee,
  FiLogIn,
  FiLogOut,
  FiStar,
  FiSun,
  FiTarget,
  FiThumbsUp,
  FiUser,
} from "react-icons/fi";

export const PREMIOS_HISTORIAL_MODIFICACIONES = {
  premios: <FiStar />,
  objetivos: <FiTarget />,
  grupos: <FiUser />,
};

export const FICHAJES_ICONS = {
  ingreso: <FiLogIn />,
  reingreso_especial: <FiLogIn />,
  inicio_almuerzo: <FiCoffee />,
  fin_almuerzo: <FiCoffee />,
  fin_jornada: <FiLogOut />,
  salida_especial: <FiLogOut />,
};

export const TIPOS_EXCEPCIONES_ICONS = {
  vacaciones: <FiSun />,
  acuerdo: <FiThumbsUp />,
  licencia: <FiCalendar />,
};
