import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;

    a {
      text-decoration: none;
    }

    .botones-container {
      display: flex;
      gap: 20px;
      align-items: center;
    }
    
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    //margin: 20px 0 20px 0;
  }

  h3 {
    margin-top: 0;
    color: ${leerColor(colores.celeste)}
  }

  h4 {
    margin: 10px 0;
  }

  .container-botones {
    display: flex;
    gap: 10px;
  }

  .data-container {
    padding: 20px 0;
    //border: 1px solid #eaeaea;
    border-radius: 6px;
    box-sizing: border-box;
  }

  /*
  .data-icon {
    font-size: 24px;
  }
  */

  .premio-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    padding: 60px 0;
    gap: 10px;
    color: ${leerColor(colores.negro)};
    font-weight: 500;
    cursor: pointer;
    transition: 0.2;
    font-size: 16px;
    text-align: center;

    :hover {
        opacity: 0.7;
    }
  }

  .grupo-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #eaeaea;

    .integrantes-container {
      display: flex;
      gap: 10px;
      border-top: 1px solid #eaeaea;
      padding: 20px;
    }

    h3 {
      font-size: 20px;
      color: ${leerColor(colores.negro)};
      padding: 20px;
      margin: 0;
    }

    span {
      font-size: 14px;
      color: ${leerColor(colores.grisEstados)}
    }
  }

  /*
  .icon {
    font-size: 36px;
    color: ${leerColor(colores.azulRebranding)};
  }
  */

  .descripcion {
    font-size: 14px;
    color: ${leerColor(colores.grisEstados)}
  }
`

export const ContainerInfo = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-top: 60px;
  //border-top: 1px solid #eaeaea;
  padding: 120px 0;

  p {
    margin: 0;
  }

  strong {
    color: ${leerColor(colores.azul)}
  }
`

export const Icon = styled.span`
    //font-size: 60px;
    display: flex;
    color: ${leerColor(colores.celeste)};
`

export const ActivePlan = styled.div`
  color: ${({ theme }) => theme.verde_texto};
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;

  .plan-inactivo {
    color: ${leerColor(colores.grisEstados)};
  }

  .container_active_plan {
    display: flex;
    align-items: center;
  }

  .blob {
    background: ${({ theme }) => theme.verde_fondo};
    border-radius: 50%;
    margin: 10px;
    height: 10px;
    width: 10px;

    box-shadow: 0 0 0 0 ${leerColor(colores.verde)};
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;