import { FiUser } from "react-icons/fi";
import { Col, Grid } from "../../../../../components/Grid";
import Subtitle from "../../../../../components/Subtitle";
import NewInputSelect from "../../../../../components/NewInputSelect";
import ErrorInput from "../../../../../components/ErrorInput";
import NewInputCalendar from "../../../../../components/NewInputCalendar";

const PeriodoExcepcion = ({ form }) => {
  const { inputValues, handleChange, formErrors } = form   

  const PERIODOS_EXCEPCIONES = [
    {
      label: "Desde / hasta",
      value: "Desde / Hasta",
      key: 1,
    },
    {
      label: "Fecha",
      value: "Fecha",
      key: 2,
    },
    {
      label: "Día",
      value: "Día",
      key: 3,
    },
  ];

  const DIAS_EXCEPCIONES = [
    {
      label: "Lunes",
      value: 1,
      key: 1,
    },
    {
      label: "Martes",
      value: 2,
      key: 2,
    },
    {
      label: "Miércoles",
      value: 3,
      key: 3,
    },
    {
      label: "Jueves",
      value: 4,
      key: 4,
    },
    {
      label: "Viernes",
      value: 5,
      key: 5,
    },
  ];

  return (
    <Grid colGap={21} rowGap={21} narrow={false}>
      <Col desktop={12}>
        <Subtitle
          title="Periodo"
          description="Defíni desde cuando se va a establecer la excepción"
          icon={<FiUser />}
          disableDivider={true}
        />
      </Col>
      <Col desktop={3}>
        <NewInputSelect
          name={"periodo"}
          labelName={"Periodo"}
          onChange={handleChange}
          placeholder={"Periodo"}
          options={PERIODOS_EXCEPCIONES}
          value={inputValues.periodo}
        />
        {formErrors.periodo && (
          <ErrorInput className="error">{formErrors.periodo}</ErrorInput>
        )}
      </Col>
      {inputValues.periodo === "Desde / Hasta" ? (
        <>
          <Col desktop={3}>
            <NewInputCalendar
              labelName={"Fecha Desde"}
              name={"fecha_desde"}
              onChange={handleChange}
              value={inputValues.fecha_desde}
            />
            {formErrors.fecha_desde && (
              <ErrorInput className="error">
                {formErrors.fecha_desde}
              </ErrorInput>
            )}
          </Col>
          <Col desktop={3}>
            <NewInputCalendar
              labelName={"Fecha Hasta"}
              name={"fecha_hasta"}
              onChange={handleChange}
              value={inputValues.fecha_hasta}
            />
            {formErrors.fecha_hasta && (
              <ErrorInput className="error">
                {formErrors.fecha_hasta}
              </ErrorInput>
            )}
          </Col>
        </>
      ) : null}
      {inputValues.periodo === "Fecha" ? (
        <Col desktop={3}>
          <NewInputCalendar
            labelName={"Fecha"}
            name={"fecha"}
            onChange={handleChange}
            value={inputValues.fecha}
          />
          {formErrors.fecha && (
            <ErrorInput className="error">{formErrors.fecha}</ErrorInput>
          )}
        </Col>
      ) : null}
      {inputValues.periodo === "Día" ? (
        <>
          <Col desktop={3}>
            <NewInputSelect
              name={"dia"}
              labelName={"Día"}
              onChange={handleChange}
              placeholder={"Día"}
              options={DIAS_EXCEPCIONES}
              value={inputValues.dia}
            />
            {formErrors.dia && (
              <ErrorInput className="error">{formErrors.dia}</ErrorInput>
            )}
          </Col>
          <Col desktop={3}>
            <NewInputCalendar
              labelName={"Fecha Desde"}
              name={"fecha_desde"}
              onChange={handleChange}
              value={inputValues.fecha_desde}
            />
            {formErrors.fecha_desde && (
              <ErrorInput className="error">
                {formErrors.fecha_desde}
              </ErrorInput>
            )}
          </Col>
          <Col desktop={3}>
            <NewInputCalendar
              labelName={"Fecha Hasta"}
              name={"fecha_hasta"}
              onChange={handleChange}
              value={inputValues.fecha_hasta}
            />
            {formErrors.fecha_hasta && (
              <ErrorInput className="error">
                {formErrors.fecha_hasta}
              </ErrorInput>
            )}
          </Col>
        </>
      ) : null}
    </Grid>
  );
};

export default PeriodoExcepcion;
