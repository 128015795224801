import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import TitlePageSection from "../../../components/TitlePageSection";
import Sidebar from "../../../components/Sidebar";
import { Breadcrumb, Container } from "./styled";
import Subtitle from "../../../components/Subtitle";
import {
  FiStar,
  FiFile,
  FiDatabase,
} from "react-icons/fi";
import { Col, Grid } from "../../../components/Grid";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useParams, useHistory } from "react-router-dom";
import NewTable from "../../../components/NewTable";
import { useTable } from "../../../hooks/useTable";
import BackButton from "../../../components/BackButton";
import { usePremio } from "./hooks/usePremio";
import Divider from "../../../components/Divider";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import Layout from "../../../components/Layout";
import NewTitlePageSection from "../../../components/NewTitlePageSection";

const Premio = () => {
  const premioId = useParams();
  const location = useLocation();

  const { order, handleTableOrder } = useTable();
  const history = useHistory();
  const formatter = new Intl.NumberFormat("es-ES", {});
  const userRol = JSON.parse(localStorage.getItem("userInfo"))?.id_rol;

  const { operaciones, tableData, descripcion, initial } = usePremio(premioId, location)

  return (
    <div>
      <div>
        {/* <Sidebar /> */}
        <Layout>
          <NewWrapper>
            <NewInner>
              <Container>
              {/* <BackButton url={location.state?.previousUrl ?? `/Premio/Vendedor/${premioId.id}`} text={"mis premios"}/> */}

                <div>
                  {userRol !== 10 && userRol !== 18 && userRol !== 22 ? (
                    <Breadcrumb>

                      <Link to={location.state?.previousUrl ?? `/Premios`} className="back-button">
                        <span>Premios</span>
                      </Link>
                      <span>/</span>
                      <span
                        onClick={() => history.goBack()}
                        className="vendedor-nombre"
                      >
                        {location.state.vendedor}
                      </span>
                      <span>/</span>
                      <span className="active">
                        {location.state.premio.nombre}
                      </span>
                    </Breadcrumb>
                  ) : (
                    <BackButton url={location.state?.previousUrl ?? `/Premio/Vendedor/${premioId.id}`} text={"mis premios"}/>
                  )}
                  <NewTitlePageSection
                    title={`Premio - ${location.state.premio.nombre}`}
                    description={descripcion}
                  ></NewTitlePageSection>
                </div>
                <Divider />
                <Subtitle
                  icon={<FiStar />}
                  title={"Datos del premio"}
                  description={
                    "Personalizá los valores y parámetros que conforman el premio"
                  }
                  disableDivider={true}
                />
                <Grid colGap={21} rowGap={21} narrow={false}>
                  <Col desktop={3}>
                    <div className={`premio-container total`}>
                      <span>Valor obtenido</span>
                      <h2>${location.state.premio.valor}</h2>
                    </div>
                  </Col>
                </Grid>
                <Divider />
                <Subtitle
                  icon={<FiDatabase />}
                  title={"Valores"}
                  description={
                    "Lista de valores utilizados para el cálculo del premio"
                  }
                  disableDivider={true}
                />
                <Grid colGap={21} rowGap={21} narrow={false}>
                  {location.state.data.data.map((item, index) => {
                    const keys = Object.keys(item).filter(
                      (item) =>
                        item !== "id_premio" &&
                        item !== "estado" &&
                        item !== "id_vendedor_premio"
                    );
                    return (
                      <>
                        {keys.map((key) => {
                          if (
                            location.state.data[key] === 1 &&
                            initial[key + "_" + index]
                          ) {
                            return (
                              <Col desktop={3}>
                                <div className={`premio-container`}>
                                  <span>{key.replaceAll("_", " ")}</span>
                                  <h2>
                                    {key.includes("valor")
                                      ? `$${formatter.format(
                                          initial[key + "_" + index]
                                        )}`
                                      : initial[key + "_" + index]}
                                  </h2>
                                </div>
                              </Col>
                            );
                          }
                        })}
                      </>
                    );
                  })}
                </Grid>
                {operaciones.length > 0 ? (
                  <>
                    <Divider />

                    {Array.isArray(operaciones[0]) ? (
                      operaciones.map((item, index) => {
                        return (
                          <>
                            <Subtitle
                              icon={<FiFile />} 
                              title={`Operaciones - ${
                                index === 0
                                  ? "Primera parte del premio"
                                  : "Segunda parte del premio"
                              }`}
                              description={`Lista de operaciones tomadas en cuenta para realizar el cálculo del premio (${item.length} resultados)`}
                              disableDivider={true}
                            />
                            <NewTable
                              columns={tableData}
                              data={item}
                              order={handleTableOrder}
                              orderParams={order}
                            />
                          </>
                        );
                      })
                    ) : (
                      <>
                        <Subtitle
                          icon={<FiFile />}
                          title={"Operaciones"}
                          description={`Lista de operaciones tomadas en cuenta para realizar el cálculo del premio (${operaciones.length} resultados)`}
                          disableDivider={true}
                        />
                        <NewTable
                          columns={tableData}
                          data={operaciones}
                          order={handleTableOrder}
                          orderParams={order}
                        />
                      </>
                    )}
                  </>
                ) : null}
              </Container>
            </NewInner>
          </NewWrapper>
        </Layout>
      </div>
    </div>
  );
};

export default Premio;
