import React, { useEffect, useState } from "react";
import Modal from "../../../Modal";
import { RechazoContainer } from "./styled";
import { Col, Grid } from "../../../Grid";
import dayjs from "dayjs";
import axiosRequest from "../../../../utils/axiosConfig";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import ErrorInput from "../../../ErrorInput";
import NewInputBasic from "../../../NewInputBasic";
import { useTabs } from "../../../../hooks/useTabs";
import NewTabs from "../../../NewTabs";
import { FiCalendar, FiCheck, FiCoffee, FiLogIn, FiLogOut, FiThumbsUp } from "react-icons/fi";
import NewInputSelect from "../../../NewInputSelect";
import NewInputCalendar from "../../../NewInputCalendar";
import Divider from "../../../Divider";
import CardSinResultados from "../../../CardSinResultados";
import { useLoading } from "../../../../hooks/useLoading";
import { useForm } from "../../../../hooks/useForm"
import { fichajePorUsuarioReglas, fichajePropioReglas } from "../../../../utils/formRules";
import { fichajePorUsuario, fichajePorUsuarioForm, fichajePropioForm } from "../../../../utils/formInitialValues";
import Success from "../../../Success";

const ModalFichajes = ({ modal, fichajes, refreshFichajes, usuarios, usuariosData }) => {
  const { isModalOpen, handleModal } = modal;
  const { tabSelected, handleTab } = useTabs()
  const [fichajeSeleccionado, setFichajeSeleccionado] = useState(null)
  const [success, setSuccess] = useState(false)
  const id_usuario = JSON.parse(localStorage.getItem("userInfo"))?.id_usuario;
  const [fichajesUsuario, setFichajesUsuario] = useState([])
  const loadingFichajesUsuario = useLoading()
  const { inputValues, handleChange, handleChangeExternal, formErrors, handleValidation } = useForm(handleFichajePorUsuario, fichajePorUsuarioReglas, fichajePorUsuarioForm)
  const fichajeForm = useForm(handleFichaje, fichajePropioReglas, fichajePropioForm)
  const id_rol = JSON.parse(localStorage.getItem("userInfo"))?.id_rol
  const rolesPermitidosExcepciones = [1, 16]
  const rolesComerciales = [10, 22]

  const usuarioComercial = rolesComerciales.includes(id_rol) ? id_rol : usuariosData.find(item => item.id_usuario == inputValues?.usuario)?.id_rol

  const ingreso = fichajes.find(item => item.descripcion_fichaje === "Ingreso")
  const inicio_almuerzo = fichajes.find(item => item.descripcion_fichaje === "Inicio almuerzo")
  const fin_almuerzo = fichajes.find(item => item.descripcion_fichaje === "Fin almuerzo")
  const salida = fichajes.find(item => item.descripcion_fichaje === "Fin jornada")
  const salida_especial = fichajes.find(item => item.descripcion_fichaje === "Salida especial")
  const reingreso_especial = fichajes.find(item => item.descripcion_fichaje === "Reingreso especial")

  const ingreso_fichaje_usuario = fichajesUsuario.length > 0 && fichajesUsuario?.find(item => item.descripcion_fichaje === "Ingreso")
  const inicio_almuerzo_fichaje_usuario = fichajesUsuario.length > 0 && fichajesUsuario.find(item => item.descripcion_fichaje === "Inicio almuerzo")
  const fin_almuerzo_fichaje_usuario = fichajesUsuario.length > 0 && fichajesUsuario.find(item => item.descripcion_fichaje === "Fin almuerzo")
  const salida_fichaje_usuario = fichajesUsuario.length > 0 && fichajesUsuario.find(item => item.descripcion_fichaje === "Fin jornada")

  let tabs = [
    {
      name: "Recurrentes",
    },
    {
      name: "Especiales",
    }
  ];

  if(rolesPermitidosExcepciones.find(id => id === id_rol)){
    tabs.push({ name: "Por usuario" })
  }

  const resetModal = () => {
    setSuccess(false)
    setFichajeSeleccionado(null)
    handleModal()
  }

  const seleccionarFichaje = (fichaje) => {
    if(tabSelected === 2){
        handleChangeExternal('fichaje', fichaje)
    } else {
        fichajeForm.handleChangeExternal('fichaje', fichaje)
    }
    setFichajeSeleccionado(fichaje)
  }

  async function handleFichajePorUsuario(){
    const rol_usuario = usuariosData.find(item => item.id_usuario == inputValues.usuario)?.id_rol    
    //const formattedDate = dayjs(new Date(`${dayjs(inputValues.fecha).format("YYYY-MM-DD")} ${inputValues.hora}`)).format("DD/MM/YYYY HH:mm:ss")  
    const formattedDate = new Date(`${dayjs(inputValues.fecha).format("YYYY-MM-DD")} ${inputValues.hora}`)

    try {
        const dataFichaje = {
            id_usuario_fichaje: inputValues.usuario,
            descripcion_fichaje: fichajeSeleccionado,
            dia_fichaje: formattedDate,
            fichajes_dia_actual: fichajesUsuario,
            rol_usuario: rol_usuario
        }
        await axiosRequest.post(`/fichaje`, dataFichaje)
        getFichajesUsuarios()
        refreshFichajes()
        setFichajeSeleccionado(null)
        setSuccess(true)
    } catch (error) {
        console.log(error)
    }
        
  }

  async function handleFichaje() {
    try {
        const dataFichaje = {
            id_usuario_fichaje: id_usuario,
            descripcion_fichaje: fichajeSeleccionado,
            dia_fichaje: new Date(),
            fichajes_dia_actual: fichajes,
            rol_usuario: id_rol
        }
        await axiosRequest.post(`/fichaje`, dataFichaje)
        refreshFichajes()
        setFichajeSeleccionado(null)
        setSuccess(true)
    } catch (error) {
        console.log(error)
    }
  }

  useEffect(() => {
    setFichajeSeleccionado(null)
  }, [tabSelected])

  const getFichajesUsuarios = async () => {
    if(inputValues.usuario && inputValues.fecha){
        loadingFichajesUsuario.setLoading(true)
        try {
            const fichajes = await axiosRequest.get(`/fichajes_usuario/${inputValues.usuario}/${inputValues.fecha}`)
            setFichajesUsuario(fichajes.data)
            loadingFichajesUsuario.setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }
}

  useEffect(() => {
    getFichajesUsuarios()
  }, [inputValues.usuario, inputValues.fecha])

  return (
    <div>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
              <div className="header-container">
                <NewTitle>Fichar</NewTitle>
                <p>
                  Cargá tu actividad en el CRM
                </p>
              </div>
              <Divider />
              {!success ?
              <>
                <div className="data-container">
                    <NewTabs
                    tabs={tabs}
                    tabSelected={tabSelected}
                    handleTab={handleTab}
                    /> 
                    {tabSelected === 0 ?
                        <Grid colGap={21} rowGap={10} narrow={true}>
                            <Col desktop={12}>
                                <div className={`fichaje ${fichajeSeleccionado === "Ingreso" && !ingreso ? 'selected' : ''}  ${ingreso ? 'fichado' : ''}`} onClick={() => seleccionarFichaje("Ingreso")}>
                                    <div className="fichaje-data">
                                        <span className="icon"><FiLogIn /></span>
                                        <div className="fichaje-info">
                                            <h4>Ingreso</h4>
                                            {ingreso ? <span>Realizado a las {ingreso.dia_fichaje_esperado ? dayjs(ingreso.dia_fichaje_esperado).format("HH:mm") : dayjs(ingreso.dia_fichaje).format("HH:mm")}hs</span> : null}
                                        </div>
                                    </div>
                                    {ingreso ?
                                    <div className="fichaje-realizado">
                                        <span className="icon-fichaje-realizado"><FiCheck /></span>
                                        <span>Fichaje realizado</span>
                                    </div> 
                                    :
                                    <div className="fichaje-realizado">
                                    <span className="icon-fichaje-disponible"><FiThumbsUp /></span>
                                    <span className="fichaje-disponible">Fichaje disponible</span>
                                    </div>
                                    }
                                </div>
                            </Col>
                            {!rolesComerciales.includes(id_rol) ?
                            <>
                            <Col desktop={12}>
                                <div className={`fichaje ${!ingreso ? 'disabled' : ''} ${fichajeSeleccionado === "Inicio almuerzo" && ingreso ? 'selected' : ''} ${inicio_almuerzo ? 'fichado' : ''}`} onClick={() => seleccionarFichaje("Inicio almuerzo")}>
                                    <div className="fichaje-data">
                                        <span className="icon"><FiCoffee /></span>
                                        <div className="fichaje-info">
                                            <h4>Inicio de almuerzo</h4>
                                            {inicio_almuerzo ? <span>Realizado a las {dayjs(inicio_almuerzo.dia_fichaje).format("HH:mm")}hs</span> : null}
                                        </div>
                                    </div>
                                    {inicio_almuerzo ?
                                    <div className="fichaje-realizado">
                                        <span className="icon-fichaje-realizado"><FiCheck /></span>
                                        <span>Fichaje realizado</span>
                                    </div> 
                                    : null}
                                    {!inicio_almuerzo && ingreso ?
                                    <div className="fichaje-realizado">
                                    <span className="icon-fichaje-disponible"><FiThumbsUp /></span>
                                    <span className="fichaje-disponible">Fichaje disponible</span>
                                    </div>
                                    : null}
                                </div>
                            </Col>
                            <Col desktop={12}>
                                <div className={`fichaje ${!inicio_almuerzo ? 'disabled' : ''} ${fichajeSeleccionado === "Fin almuerzo" && inicio_almuerzo ? 'selected' : ''}  ${fin_almuerzo ? 'fichado' : ''}`} onClick={() => seleccionarFichaje("Fin almuerzo")}>
                                    <div className="fichaje-data">
                                        <span className="icon"><FiCoffee /></span>
                                        <div className="fichaje-info">
                                            <h4>Fin de almuerzo</h4>
                                            {fin_almuerzo ? <span>Realizado a las {dayjs(fin_almuerzo.dia_fichaje).format("HH:mm")}hs</span> : null}
                                        </div>
                                    </div>
                                    {fin_almuerzo ?
                                        <div className="fichaje-realizado">
                                            <span className="icon-fichaje-realizado"><FiCheck /></span>
                                            <span>Fichaje realizado</span>
                                        </div> 
                                    : null}
                                    {!fin_almuerzo && inicio_almuerzo ?
                                        <div className="fichaje-realizado">
                                            <span className="icon-fichaje-disponible"><FiThumbsUp /></span>
                                            <span className="fichaje-disponible">Fichaje disponible</span>
                                        </div>
                                    : null}
                                </div>
                            </Col>
                            </>
                            : null}
                            <Col desktop={12}>
                                <div className={`fichaje ${(!fin_almuerzo && !rolesComerciales.includes(usuarioComercial)) || (usuarioComercial && !ingreso) ? 'disabled' : ''} 
                                ${(fichajeSeleccionado === "Fin jornada" && fin_almuerzo && !usuarioComercial) || (fichajeSeleccionado === "Fin jornada" && ingreso && usuarioComercial) ? 'selected' : ''}  
                                ${salida ? 'fichado' : ''}`} onClick={() => seleccionarFichaje("Fin jornada")}>
                                    <div className="fichaje-data">
                                        <span className="icon"><FiLogOut /></span>
                                        <div className="fichaje-info">
                                            <h4>Salida</h4>
                                            {salida ? <span>Realizado a las {salida.dia_fichaje_esperado ? dayjs(salida.dia_fichaje_esperado).format("HH:mm") : dayjs(salida.dia_fichaje).format("HH:mm")}hs</span> : null}
                                        </div>
                                    </div>
                                    {salida ?
                                    <div className="fichaje-realizado">
                                        <span className="icon-fichaje-realizado"><FiCheck /></span>
                                        <span>Fichaje realizado</span>
                                    </div> 
                                    : null}
                                    
                                    {fin_almuerzo && !salida ?
                                    <div className="fichaje-realizado">
                                        <span className="icon-fichaje-disponible"><FiThumbsUp /></span>
                                        <span className="fichaje-disponible">Fichaje disponible</span>
                                    </div>
                                    : null}
                                </div>
                            </Col>
                            <Col desktop={12}>
                                {fichajeForm.formErrors.fichaje ? <ErrorInput>Es necesario seleccionar un tipo de fichaje.</ErrorInput> : null}
                            </Col>
                        </Grid>
                    : null}
                    {tabSelected === 1 ?
                        <Grid colGap={21} rowGap={21} narrow={true}>
                            <Col desktop={12}>
                                <div className={`fichaje ${fichajeSeleccionado === "Salida especial" && !salida_especial ? 'selected' : ''}  ${salida_especial ? 'fichado' : ''}`} onClick={() => seleccionarFichaje("Salida especial")}>
                                    <div className="fichaje-data">
                                        <span className="icon"><FiLogOut /></span>
                                        <div className="fichaje-info">
                                            <h4>Salida especial</h4>
                                            {salida_especial ? <span>Realizado a las {dayjs(ingreso.dia_fichaje).format("HH:mm")}hs</span> : null}
                                        </div>
                                    </div>
                                    {salida_especial ?
                                    <div className="fichaje-realizado">
                                        <span className="icon-fichaje-realizado"><FiCheck /></span>
                                        <span>Fichaje realizado</span>
                                    </div>
                                    : null}
                                    {!salida_especial ?
                                    <div className="fichaje-realizado">
                                        <span className="icon-fichaje-disponible"><FiThumbsUp /></span>
                                        <span className="fichaje-disponible">Fichaje disponible</span>
                                    </div>
                                    : null}
                                </div>
                            </Col>
                            <Col desktop={12}>
                                <div className={`fichaje ${!salida_especial ? 'disabled' : ''} ${fichajeSeleccionado === "Inicio almuerzo" && salida_especial ? 'selected' : ''} ${reingreso_especial ? 'fichado' : ''}`} onClick={() => seleccionarFichaje("Reingreso especial")}>
                                    <div className="fichaje-data">
                                        <span className="icon"><FiLogIn /></span>
                                        <div className="fichaje-info">
                                            <h4>Reingreso especial</h4>
                                            {reingreso_especial ? <span>Realizado a las {dayjs(ingreso.dia_fichaje).format("HH:mm")}hs</span> : null}
                                        </div>
                                    </div>
                                    {reingreso_especial ?
                                    <div className="fichaje-realizado">
                                        <span className="icon-fichaje-realizado"><FiCheck /></span>
                                        <span>Fichaje realizado</span>
                                    </div>
                                    : null}
                                    {!reingreso_especial && salida_especial ?
                                    <div className="fichaje-realizado">
                                        <span className="icon-fichaje-disponible"><FiThumbsUp /></span>
                                        <span className="fichaje-disponible">Fichaje disponible</span>
                                    </div>
                                    : null}
                                </div>
                            </Col>
                        </Grid>
                    : null}
                    {tabSelected === 2 ?
                        <>
                        <Grid colGap={21} rowGap={21} narrow={true}>
                            <Col desktop={5}>
                                <NewInputSelect
                                labelName="Usuario"
                                name={"usuario"}
                                onChange={handleChange}
                                placeholder={"Usuario"}
                                options={usuarios}
                                value={inputValues.usuario}
                                />
                                {formErrors.usuario ? <ErrorInput>{formErrors.usuario}</ErrorInput> : null}
                            </Col>
                        <Col desktop={4}>
                        <NewInputCalendar
                                labelName={"Fecha"}
                                name={"fecha"}
                                onChange={handleChange}
                                value={inputValues.fecha}
                            />
                        {formErrors.fecha ? <ErrorInput>{formErrors.fecha}</ErrorInput> : null}
                        </Col>
                        <Col desktop={3}>
                            <NewInputBasic
                            type="hora"
                            labelName="Hora"
                            name="hora"
                            placeholder="14:20"
                            onChange={handleChange}
                            value={inputValues.hora}
                            />
                            {formErrors.hora ? <ErrorInput>{formErrors.hora}</ErrorInput> : null}
                        </Col>
                        </Grid>
                        <Divider />
                        <Grid colGap={21} rowGap={21} narrow={true}>
                        {inputValues.usuario && inputValues.fecha ?
                        <>
                            <Col desktop={12}>
                                <div className={`fichaje ${fichajeSeleccionado === "Ingreso" && !ingreso_fichaje_usuario ? 'selected' : ''}  ${ingreso_fichaje_usuario ? 'fichado' : ''}`} onClick={() => seleccionarFichaje("Ingreso")}>
                                    <div className="fichaje-data">
                                        <span className="icon"><FiLogIn /></span>
                                        <div className="fichaje-info">
                                            <h4>Ingreso</h4>
                                            {ingreso_fichaje_usuario ? <span>Realizado a las {ingreso_fichaje_usuario.dia_fichaje_esperado ? dayjs(ingreso_fichaje_usuario.dia_fichaje_esperado).format("HH:mm") : dayjs(ingreso_fichaje_usuario.dia_fichaje).format("HH:mm")}hs</span> : null}
                                        </div>
                                    </div>
                                    {ingreso_fichaje_usuario ?
                                    <div className="fichaje-realizado">
                                        <span className="icon-fichaje-realizado"><FiCheck /></span>
                                        <span>Fichaje realizado</span>
                                    </div> 
                                    :
                                    <div className="fichaje-realizado">
                                    <span className="icon-fichaje-disponible"><FiThumbsUp /></span>
                                    <span className="fichaje-disponible">Fichaje disponible</span>
                                    </div>
                                    }
                                </div>
                            </Col>
                            {!rolesComerciales.includes(usuarioComercial) ?
                            <>
                            <Col desktop={12}>
                                <div className={`fichaje ${!ingreso_fichaje_usuario ? 'disabled' : ''} ${fichajeSeleccionado === "Inicio almuerzo" && ingreso_fichaje_usuario ? 'selected' : ''} ${inicio_almuerzo_fichaje_usuario ? 'fichado' : ''}`} onClick={() => seleccionarFichaje("Inicio almuerzo")}>
                                    <div className="fichaje-data">
                                        <span className="icon"><FiCoffee /></span>
                                        <div className="fichaje-info">
                                            <h4>Inicio de almuerzo</h4>
                                            {inicio_almuerzo_fichaje_usuario ? <span>Realizado a las {dayjs(inicio_almuerzo_fichaje_usuario.dia_fichaje).format("HH:mm")}hs</span> : null}
                                        </div>
                                    </div>
                                    {inicio_almuerzo_fichaje_usuario ?
                                    <div className="fichaje-realizado">
                                        <span className="icon-fichaje-realizado"><FiCheck /></span>
                                        <span>Fichaje realizado</span>
                                    </div> 
                                    : null}
                                    {ingreso_fichaje_usuario && !inicio_almuerzo_fichaje_usuario ?
                                    <div className="fichaje-realizado">
                                    <span className="icon-fichaje-disponible"><FiThumbsUp /></span>
                                    <span className="fichaje-disponible">Fichaje disponible</span>
                                    </div>
                                    : null}
                                </div>
                            </Col>
                            <Col desktop={12}>
                                <div className={`fichaje ${!inicio_almuerzo_fichaje_usuario ? 'disabled' : ''} ${fichajeSeleccionado === "Fin almuerzo" && inicio_almuerzo_fichaje_usuario ? 'selected' : ''}  ${fin_almuerzo_fichaje_usuario ? 'fichado' : ''}`} onClick={() => seleccionarFichaje("Fin almuerzo")}>
                                    <div className="fichaje-data">
                                        <span className="icon"><FiCoffee /></span>
                                        <div className="fichaje-info">
                                            <h4>Fin de almuerzo</h4>
                                            {fin_almuerzo_fichaje_usuario ? <span>Realizado a las {dayjs(fin_almuerzo_fichaje_usuario.dia_fichaje).format("HH:mm")}hs</span> : null}
                                        </div>
                                    </div>
                                    {fin_almuerzo_fichaje_usuario ?
                                        <div className="fichaje-realizado">
                                            <span className="icon-fichaje-realizado"><FiCheck /></span>
                                            <span>Fichaje realizado</span>
                                        </div> 
                                    : null}
                                    {inicio_almuerzo_fichaje_usuario && !fin_almuerzo_fichaje_usuario ?
                                        <div className="fichaje-realizado">
                                            <span className="icon-fichaje-disponible"><FiThumbsUp /></span>
                                            <span className="fichaje-disponible">Fichaje disponible</span>
                                        </div>
                                    : null}
                                </div>
                            </Col>
                            </>
                            : null}
                            <Col desktop={12}>
                                <div className={`fichaje ${(!fin_almuerzo_fichaje_usuario && !rolesComerciales.includes(usuarioComercial)) || (usuarioComercial && !ingreso_fichaje_usuario) ? 'disabled' : ''} ${(fichajeSeleccionado === "Fin jornada" && fin_almuerzo_fichaje_usuario && !usuarioComercial) || (fichajeSeleccionado === "Fin jornada" && ingreso_fichaje_usuario && usuarioComercial) ? 'selected' : ''}  ${salida_fichaje_usuario ? 'fichado' : ''}`} onClick={() => seleccionarFichaje("Fin jornada")}>
                                    <div className="fichaje-data">
                                        <span className="icon"><FiLogOut /></span>
                                        <div className="fichaje-info">
                                            <h4>Salida</h4>
                                            {salida_fichaje_usuario ? <span>Realizado a las {salida_fichaje_usuario.dia_fichaje_esperado ? dayjs(salida_fichaje_usuario.dia_fichaje_esperado).format("HH:mm") : dayjs(salida_fichaje_usuario.dia_fichaje).format("HH:mm")}hs</span> : null}
                                        </div>
                                    </div>
                                    {salida_fichaje_usuario ?
                                    <div className="fichaje-realizado">
                                        <span className="icon-fichaje-realizado"><FiCheck /></span>
                                        <span>Fichaje realizado</span>
                                    </div> 
                                    : null}

                                    {fin_almuerzo_fichaje_usuario && !salida_fichaje_usuario ?
                                    <div className="fichaje-realizado">
                                    <span className="icon-fichaje-disponible"><FiThumbsUp /></span>
                                    <span className="fichaje-disponible">Fichaje disponible</span>
                                    </div>
                                    : null}
                                </div>
                            </Col>
                            {!rolesComerciales.includes(usuarioComercial) ?
                            <>
                            <Col desktop={12}>
                                <div className={`fichaje ${!ingreso_fichaje_usuario ? 'disabled' : ''} ${fichajeSeleccionado === "Salida especial" && !salida_especial ? 'selected' : ''}  ${salida_especial ? 'fichado' : ''}`} onClick={() => seleccionarFichaje("Salida especial")}>
                                    <div className="fichaje-data">
                                        <span className="icon"><FiLogOut /></span>
                                        <div className="fichaje-info">
                                            <h4>Salida especial</h4>
                                            {salida_especial ? <span>Realizado a las {dayjs(ingreso.dia_fichaje).format("HH:mm")}hs</span> : null}
                                        </div>
                                    </div>
                                    {salida_especial ?
                                    <div className="fichaje-realizado">
                                        <span className="icon-fichaje-realizado"><FiCheck /></span>
                                        <span>Fichaje realizado</span>
                                    </div>
                                    : null}
                                    {!salida_especial && ingreso_fichaje_usuario ?
                                    <div className="fichaje-realizado">
                                        <span className="icon-fichaje-disponible"><FiThumbsUp /></span>
                                        <span className="fichaje-disponible">Fichaje disponible</span>
                                    </div>
                                    : null}
                                </div>
                            </Col>
                            <Col desktop={12}>
                                <div className={`fichaje ${!salida_especial ? 'disabled' : ''} ${fichajeSeleccionado === "Inicio almuerzo" && salida_especial ? 'selected' : ''} ${reingreso_especial ? 'fichado' : ''}`} onClick={() => seleccionarFichaje("Reingreso especial")}>
                                    <div className="fichaje-data">
                                        <span className="icon"><FiLogIn /></span>
                                        <div className="fichaje-info">
                                            <h4>Reingreso especial</h4>
                                            {reingreso_especial ? <span>Realizado a las {dayjs(ingreso.dia_fichaje).format("HH:mm")}hs</span> : null}
                                        </div>
                                    </div>
                                    {reingreso_especial ?
                                    <div className="fichaje-realizado">
                                        <span className="icon-fichaje-realizado"><FiCheck /></span>
                                        <span>Fichaje realizado</span>
                                    </div>
                                    : null}
                                    {!reingreso_especial && salida_especial ?
                                    <div className="fichaje-realizado">
                                        <span className="icon-fichaje-disponible"><FiThumbsUp /></span>
                                        <span className="fichaje-disponible">Fichaje disponible</span>
                                    </div>
                                    : null}
                                </div>
                            </Col>
                            </> 
                            : null}
                            <Col desktop={12}>
                                {formErrors.fichaje ? <ErrorInput>{formErrors.fichaje}</ErrorInput> : null}
                            </Col>
                            </>
                        :
                        <Col desktop={12}>
                            <CardSinResultados icon={<FiCalendar />} title="No hay fichajes" description={"Seleccioná un usuario y una fecha para ver la lista de fichajes disponibles"}/>
                        </Col>
                        }
                        </Grid>
                        </>
                    : null}
                </div>
                <Divider />
                <div className={"actionButtonsCont"}>
                    <div className="buttonsCont">
                    <NewButton
                        backgroundColor={false}
                        textColor={true}
                        borderColor={true}
                        onClick={resetModal}
                    >
                        Cancelar
                    </NewButton>
                    <NewButton backgroundColor={true} onClick={tabSelected === 2 ? handleValidation : fichajeForm.handleValidation} >
                        Fichar {fichajeSeleccionado}
                    </NewButton>
                    </div>
                </div>
              </>
              :
                <Success title="¡Fichaje creado!" description={`Se cargó el nuevo fichaje correctamente`} handleModal={resetModal} />
              }
        </RechazoContainer>
      </Modal>
    </div>
  );
};

export default ModalFichajes;
