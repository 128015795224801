import React from "react";
import Modal from "../../../Modal";
import { RechazoContainer } from "./styled";
import { Col, Grid } from "../../../Grid";
import { useForm } from "../../../../hooks/useForm";
import ErrorInput from "../../../ErrorInput";
import { objetivosReglas } from "../../../../utils/formRules";
import { objetivosForm } from "../../../../utils/formInitialValues";
import { useLoading } from "../../../../hooks/useLoading";
import ModalSuccess from "../../../ModalSuccess";
import useModal from "../../../../hooks/useModal";
import axiosRequest from "../../../../utils/axiosConfig";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import NewInputBasic from "../../../NewInputBasic";
import { useEffect } from "react";
import { meses } from "../../../../constants/data";
import Divider from "../../../Divider";
import { useState } from "react";
import Subtitle from "../../../Subtitle";
import { FiDollarSign } from "react-icons/fi";
import GoalConverter from "../../../GoalConverter";
import Success from "../../../Success";

const ModalObjetivos = ({ modal, vendedor, objetivos, refreshData }) => {
  const { isModalOpen, handleModal } = modal;
  const {
    inputValues,
    handleChange,
    formErrors,
    handleValidation,
    completeForm,
  } = useForm(handleSubmit, objetivosReglas, objetivosForm);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const mes = new Date().getMonth();
  const [success, setSuccess] = useState(false)

  const { loading, setLoading, changeLoadingText } =
    useLoading(false);
  const exitoModal = useModal();

  useEffect(() => {
    if (objetivos) {
      const { operaciones, prima } = objetivos;
      completeForm({ operaciones, prima });
    }
  }, [objetivos]);

  async function handleSubmit() {
    setLoading(true);
    changeLoadingText("Creando objetivos...");
    try {
      if (objetivos) {
        const update = await axiosRequest.put(
          `/premios/vendedor_objetivos/${vendedor.id_vendedor}`,
          inputValues
        );
        if (update.status === 200) {
          const historial = {
            mensaje: `${
              user.nombre_operador
            } editó los objetivos del mes de ${meses
              .find((item) => item.value === mes)
              .label.toLowerCase()} para ${vendedor.vendedor}. Operaciones: ${
              inputValues.operaciones
            }, Prima: $${inputValues.prima}`,
            dia: new Date(),
            id_vendedor: vendedor.id_vendedor,
            id_premio: null,
            id_usuario: user.id_operador,
            id_tipo_modificacion: 2,
          };
          await axiosRequest.post(
            `/premios/historial_modificaciones`,
            historial
          );
          await refreshData();
          setSuccess(true)
          setLoading(false);
        }
      } else {
        const crear = await axiosRequest.post(
          `/premios/vendedor/objetivos/${vendedor.id_vendedor}`,
          inputValues
        );
        if (crear.status === 200) {
          // HISTORIAL MODIFICACIONES
          const historial = {
            mensaje: `${
              user.nombre_operador
            } creó los objetivos del mes de ${meses
              .find((item) => item.value === mes)
              .label.toLowerCase()} para ${vendedor.vendedor}. Operaciones: ${
              inputValues.operaciones
            }, Prima: $${inputValues.prima}`,
            dia: new Date(),
            id_vendedor: vendedor.id_vendedor,
            id_premio: null,
            id_usuario: user.id_operador,
            id_tipo_modificacion: 2,
          };
          await axiosRequest.post(
            `/premios/historial_modificaciones`,
            historial
          );
          await refreshData();
          setSuccess(true)
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <div>
      <ModalSuccess
        isSuccessPopUpOpen={exitoModal.isModalOpen}
        setIsSuccessPopUpOpen={exitoModal.handleModal}
        popUpMessage={"¡Objetivos cargados!"}
      />
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
            <NewTitle>Objetivos</NewTitle>
            <p>
              Completá los objetivos de {vendedor.vendedor} para el mes actual
            </p>
          </div>
          <Divider />
          {!success ?
          <>
          <div className="data-container">
            <div className="conversor-prima-container">
              <Subtitle
                icon={<FiDollarSign />}
                title={"Conversor de prima"}
                description={"Convertí un valor de prima anual para obtener un nuevo valor de objetivo"}
                disableDivider={true}
              />
              <GoalConverter />
            </div>
            <Divider />
            <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={6}>
                <NewInputBasic
                  type="number"
                  labelName="Operaciones"
                  name="operaciones"
                  placeholder="100"
                  onChange={handleChange}
                  value={inputValues.operaciones}
                />
                {formErrors.operaciones && (
                  <ErrorInput>{formErrors.operaciones}</ErrorInput>
                )}
              </Col>
              <Col desktop={6}>
                <NewInputBasic
                  type="number"
                  labelName="Prima"
                  name="prima"
                  placeholder="1000000"
                  onChange={handleChange}
                  value={inputValues.prima}
                />
                {formErrors.prima && (
                  <ErrorInput>{formErrors.prima}</ErrorInput>
                )}
              </Col>
            </Grid>
          </div>
          <Divider />
          <div className={"actionButtonsCont"}>
            <div className="buttonsCont">
              <NewButton
                backgroundColor={false}
                textColor={true}
                borderColor={true}
                onClick={handleModal}
              >
                Cancelar
              </NewButton>
              <NewButton
                backgroundColor={true}
                onClick={handleValidation}
                disabled={loading}
              >
                {loading
                  ? `${objetivos ? "Actualizando" : "Creando"} objetivos...`
                  : `${objetivos ? "Actualizar" : "Crear"} objetivos`}
              </NewButton>
            </div>
          </div>
          </>
          :
          <Success title={`¡Objetivos ${objetivos ? "actualizados" : "creados"}!`} description={`Los objetivos fueron ${objetivos ? "actualizados" : "creados"} correctamente`} handleModal={handleModal}/>
          }
        </RechazoContainer>
      </Modal>
    </div>
  );
};

export default ModalObjetivos;
