import { Container } from "./styled";
// Components
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import TitlePageSection from "../../../components/TitlePageSection";
import Sidebar from "../../../components/Sidebar";
import { Col, Grid } from "../../../components/Grid";
import PieChart from "../../../components/PieChart";
import { colores, leerColor } from "../../../constants";
import Divider from "../../../components/Divider";
import NewTable from "../../../components/NewTable";
import { useTable } from "../../../hooks/useTable";
import BarChart from "../../../components/BarChart";
import NewInputSelect from "../../../components/NewInputSelect";
import { useEffect } from "react";
import { useState } from "react";
import axiosRequest from "../../../utils/axiosConfig";
import formatSelectData from "../../../utils/formatSelectData";
import {
  FiCheck,
  FiDollarSign,
  FiFilter,
  FiList,
  FiServer,
  FiTablet,
  FiUser,
} from "react-icons/fi";
import Subtitle from "../../../components/Subtitle";
import { getCompanias, getMediosPago } from "../../../services/Rechazos";
import { meses } from "../../../constants/data";
import NewInputCalendar from "../../../components/NewInputCalendar";
import NewTabs from "../../../components/NewTabs";
import { useTabs } from "../../../hooks/useTabs";
import { LOGOS_COMPANIAS } from "../../../constants/logos-companias";
import SpinnerLoading from "../../../components/SpinnerLoading";
import dayjs from "dayjs";
import { useLoading } from "../../../hooks/useLoading";
import Layout from "../../../components/Layout";
import NewWrapper from "../../../components/NewWrapper";
import NewInner from "../../../components/NewInner";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import { useTheme } from "../../../context/ThemeContext";

const NewEstadisticasCobranzas = () => {
  const { order, handleTableOrder } = useTable();
  const [companias, setCompanias] = useState([]);
  const [formasPago, setFormasPago] = useState([]);
  const [companiaMedioPagoFormated, setCompaniaMedioPagoFormated] = useState([
    1, 1, 1, 1,
  ]);
  const [labelCiaMedioPago, setLabelCiaMedioPago] = useState(
    "COMPAÑIA - FORMA DE PAGO"
  );
  const [selectCompania, setSelectCompania] = useState([]);
  const [selectFormaPago, setSelectFormaPago] = useState("");
  const { theme } = useTheme()

  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const { tabSelected, handleTab } = useTabs();
  const [filters, setFilters] = useState({
    compania: 0,
    forma_pago: 0,
    fecha_desde: dayjs(firstDay).format("YYYY-MM-DD"),
    fecha_hasta: dayjs(lastDay).format("YYYY-MM-DD"),
  });

  const [estadisticasGenerales, setEstadisticasGenerales] = useState({});
  const [estadisticasImportes, setEstadisticasImportes] = useState({});
  const [companiaFormaPago, setCompaniaFormaPago] = useState([]);
  const [tablaAnalistas, setTablaAnalistas] = useState([]);
  const [informes, setInformes] = useState([]);
  const formatter = new Intl.NumberFormat("es-ES", {});
  const { loading, setLoading } = useLoading(true);

  const ESTADISTICAS_COBRANZAS_TAB = [
    {
      name: "Gráficos",
    },
    {
      name: "Informe",
    },
  ];

  const getEstadisticasGenerales = async () => {
    try {
      setLoading(true);
      setInformes([]);
      // hace la peticion que trae todas las estadisticas
      let { data } = await axiosRequest.get(
        `/rechazos/estadisticas/generales`,
        {
          params: {
            fecha_desde: filters.fecha_desde,
            fecha_hasta: filters.fecha_hasta,
          },
        }
      );
      if (data) {
        const id_compania = filters.compania ? parseInt(filters.compania) : 0;
        const importe_compania =
          data.graficos.estadistica_importe_compania.data.find(
            (item) => item.id_compania === id_compania
          );
        const { importe_positivo, importe_negativo, importe_cerrado } = importe_compania;
        console.error('tablas',data.tablas)

        const {tablaResume} = data.tablas[data.tablas.length - 1];

        delete data.tablas[data.tablas.length - 1]
        setTablaAnalistas(tablaResume);
        setEstadisticasImportes({
          importe_positivo: importe_positivo.toFixed(2),
          importe_negativo: importe_negativo.toFixed(2),
          importe_cerrado: importe_cerrado.toFixed(2),
        });
      }
      setEstadisticasGenerales(data.graficos);
      setInformes(data.tablas);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // 1er grafico
  const getEstadisticasCompaniaMedioPago = async () => {
    try {
      // hace la peticion que trae todas las estadisticas
      let { data } = await axiosRequest.get(
        `/rechazos/estadisticas/compania_medio_pago`,
        {
          params: {
            fecha_desde: filters.fecha_desde, // imprelementar select
            fecha_hasta: filters.fecha_hasta, // imprelementar select
            id_compania: parseInt(filters.compania), // imprelementar select
            id_medio_pago: parseInt(filters.forma_pago), // imprelementar select // 9999 es efectivo
          },
        }
      );
      setCompaniaFormaPago(Object.values(data.data));
    } catch (error) {
      console.log(error);
    }
  };

  const getMetaData = async () => {
    try {
      let allCompanias = await getCompanias();
      allCompanias.unshift({
        label: "TODAS LAS COMPANIAS",
        key: 0,
        value: 0,
      });
      setCompanias(allCompanias);

      let allMediosPagos = await getMediosPago();
      allMediosPagos.unshift(
        {
          label: "TODOS LOS MEDIOS",
          key: 0,
          value: 0,
        },
        {
          label: "EFECTIVO",
          key: "efectivo",
          value: 9999,
        }
      );
      setFormasPago(allMediosPagos);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getData = async () => {
      await getMetaData(); // trae y setea compania y medio de pago
      if (selectCompania && selectFormaPago) {
        setLabelCiaMedioPago("BUSCANDO");
        setCompaniaMedioPagoFormated([1, 1, 1, 1]);
      }
      await getEstadisticasGenerales();
      await getEstadisticasCompaniaMedioPago();
    };
    getData();
  }, [
    filters.fecha_desde,
    filters.fecha_hasta,
    filters.compania,
    filters.forma_pago,
  ]);

  const tableData = [
    {
      header: "Analista",
      name: "analista",
    },
    {
      header: "Resuelto Positivo",
      name: "pagado",
    },
    {
      header: "En Gestión",
      name: "en_gestion",
    },
    {
      header: "Promesa Pago",
      name: "promesa_pago",
    },
    {
      header: "Negativo Derivado",
      name: "derivado",
    },
    {
      header: "Negativo Cerrado",
      name: "cerrado",
    },
    {
      header: "Efectividad",
      name: "efectividad",
      component: (item) => {
        return <td width="150px">{item.efectividad}%</td>;
      },
    },
  ];

  const tableDataInforme = [
    {
      header: "Compañia",
      name: "id_compania",
      component: (item) => {
        const compania = companias.find(
          (compania) => parseInt(compania.value) === item.id_compania
        );
        let logo = null;
        if (compania) {
          logo = LOGOS_COMPANIAS.find((logos) => {
            return logos.nombre === compania?.label;
          });
        }

        return (
          <td width="120px" className="compania">
            {logo  ? <img src={logo.url} alt="logo compañia" /> : "Total"}
          </td>
        );
      },
    },
    {
      header: "Acumulado",
      name: "acumulado",
    },
    {
      header: "Ingresos",
      name: "ingresos",
    },
    {
      header: "Arrastres",
      name: "arrastres",
    },
    {
      header: "Resuelto Positivo",
      name: "pagado",
    },
    {
      header: "En Gestión",
      name: "en_gestion",
    },
    {
      header: "Negativo Derivado",
      name: "derivado",
    },
    {
      header: "Negativo Cerrado",
      name: "cerrado",
    },
    {
      header: "Efectividad",
      name: "efectividad",
      component: (item) => {
        return <td width="150px">{item.efectividad}%</td>;
      },
    },
  ];

  const tableDataInformeImporte = [
    {
      header: "Compañia",
      name: "id_compania",
      component: (item) => {
        const compania = companias.find(
          (compania) => parseInt(compania.value) === item.id_compania
        );
        let logo = null;
        if (compania) {
          logo = LOGOS_COMPANIAS.find((logos) => {
            return logos.nombre === compania?.label;
          });
        }

        return (
          <td width="120px" className="compania">
            {logo  ? <img src={logo.url} alt="logo compañia" /> : "Total"}
          </td>
        );
      },
    },
    {
      header: "Acumulado",
      name: "acumulado",
      component: (item) => {
        return <td width="150px">${formatter.format(item.acumulado)}</td>;
      },
    },
    {
      header: "Ingresos",
      name: "ingresos",
      component: (item) => {
        return <td width="150px">${formatter.format(item.ingresos)}</td>;
      },
    },
    {
      header: "Arrastres",
      name: "arrastres",
      component: (item) => {
        return <td width="150px">${formatter.format(item.arrastres)}</td>;
      },
    },
    {
      header: "Resuelto Positivo",
      name: "pagado",
      component: (item) => {
        return <td width="150px">${formatter.format(item.pagado)}</td>;
      },
    },
    {
      header: "En Gestión",
      name: "en_gestion",
      component: (item) => {
        return <td width="150px">${formatter.format(item.en_gestion)}</td>;
      },
    },
    {
      header: "Negativo Derivado",
      name: "derivado",
      component: (item) => {
        return <td width="150px">${formatter.format(item.derivado)}</td>;
      },
    },
    {
      header: "Negativo Cerrado",
      name: "cerrado",
      component: (item) => {
        return <td width="150px">${formatter.format(item.cerrado)}</td>;
      },
    },
    {
      header: "Efectividad",
      name: "efectividad",
      component: (item) => {
        return <td width="150px">{item.efectividad}%</td>;
      },
    },
  ];

  const handleSelect1Change = (event) => {
    setSelectCompania(event.target.value);
  };

  const handleSelect2Change = (event) => {
    setSelectFormaPago(event.target.value);
  };

  const optionsPagoEmpresa = {
    responsive: true,
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: theme === "dark" ? "#ffffff" : '#666666',
        },
        grid: {
          borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
          tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
          color: theme === "dark" ? "#243158" : '#e5e5e5',
        }
      },
      y: {
        stacked: false,
        ticks: {
          color: theme === "dark" ? "#ffffff" : '#666666',
        },
        grid: {
          borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
          tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
          color: theme === "dark" ? "#243158" : '#e5e5e5',
        }
      },
    },
    plugins: {
      title: {
        display: false,
        text: `Estadísticas de rechazos para Federación Patronal con tarjeta de crédito`,
        color: theme === "dark" ? "#ffffff" : "#666666",
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 10,
          color: theme === "dark" ? "#ffffff" : "#666666",
        },
      },
    },
  };


  const optionsPie = {
    plugins: {
      responsive: true,
      scales: {
        x: {
          stacked: false,
          ticks: {
            color: theme === "dark" ? "#ffffff" : '#666666',
          },
          grid: {
            borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
            tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
            color: theme === "dark" ? "#243158" : '#e5e5e5',
          }
        },
        y: {
          stacked: false,
          ticks: {
            color: theme === "dark" ? "#ffffff" : '#666666',
          },
          grid: {
            borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
            tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
            color: theme === "dark" ? "#243158" : '#e5e5e5',
          }
        },
      },
      title: {
        display: false,
        color: theme === "dark" ? "#ffffff" : "#666666",
        text: "Estado de rechazos",
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 10,
          color: theme === "dark" ? "#ffffff" : "#666666",
        },
      },
    },
  };

  const optionsImporte = {
    plugins: {
      responsive: true,
      scales: {
        x: {
          stacked: false,
          ticks: {
            color: theme === "dark" ? "#ffffff" : '#666666',
          },
          grid: {
            borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
            tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
            color: theme === "dark" ? "#243158" : '#e5e5e5',
          }
        },
        y: {
          stacked: false,
          ticks: {
            color: theme === "dark" ? "#ffffff" : '#666666',
          },
          grid: {
            borderColor: theme === "dark" ? "#243158" : '#e5e5e5',
            tickColor: theme === "dark" ? "#243158" : '#e5e5e5',
            color: theme === "dark" ? "#243158" : '#e5e5e5',
          }
        },
      },
      title: {
        display: false,
        text: "Importe según compañia",
      },
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      {/* <Sidebar /> */}
      <Layout>
        <NewWrapper>
          <NewInner>
            <Container>
              <NewTitlePageSection
                title="Estadísticas Cobranzas"
                description="Información sobre actividad de rechazos"
              ></NewTitlePageSection>

              <Divider />

            <Grid colGap={21} rowGap={21} narrow={false}>
              <Col desktop={6}>
                <Subtitle
                  icon={<FiFilter />}
                  title={`Filtros Generales`}
                  description={"Aplica para gráficos y tablas"}
                  disableDivider={true}
                />
              </Col>
              <Col desktop={3}>
                <NewInputCalendar
                  name={"fecha_desde"}
                  labelName={"Fecha Desde"}
                  onChange={handleChange}
                  value={filters.fecha_desde}
                />
              </Col>
              <Col desktop={3}>
                <NewInputCalendar
                  name={"fecha_hasta"}
                  labelName={"Fecha Hasta"}
                  onChange={handleChange}
                  value={filters.fecha_hasta}
                />
              </Col>
            </Grid>

            <NewTabs
              tabs={ESTADISTICAS_COBRANZAS_TAB}
              tabSelected={tabSelected}
              handleTab={handleTab}
            />

            {tabSelected === 0 ? (
              !loading ? (
                <>
                  <Grid colGap={21} rowGap={21} narrow={false}>
                    <Col desktop={9}>
                      <Subtitle
                        icon={<FiFilter />}
                        title={`Filtros Específicos`}
                        description={`Aplica para gráfico "Forma de pago y compañia" e "Importe por compañia"`}
                        disableDivider={true}
                      />
                    </Col>
                    <Col desktop={3}>
                      <NewInputSelect
                        name={"compania"}
                        //labelName={"Compañia"}
                        placeholder={"Compañia"}
                        id={"compania"}
                        options={companias}
                        onChange={handleChange}
                        value={filters.compania}
                      />
                    </Col>
                    <Col desktop={12}>
                      <Divider />
                    </Col>
                    <Col desktop={9}>
                      <Subtitle
                        icon={<FiDollarSign />}
                        title={`Forma de pago y compañias`}
                        description={`Estadísticas de rechazos según forma de pago (${
                          formasPago.find(
                            (item) =>
                              parseInt(item.value) ===
                              parseInt(filters.forma_pago)
                          )?.label
                        }) y compañia (${
                          companias.find(
                            (item) =>
                              parseInt(item.value) ===
                              parseInt(filters.compania)
                          )?.label
                        })`}
                        disableDivider={true}
                      />
                    </Col>
                    <Col desktop={3}>
                      <NewInputSelect
                        name={"forma_pago"}
                        //labelName={"Forma de Pago"}
                        placeholder={"Forma de pago"}
                        options={formasPago}
                        onChange={handleChange}
                        value={filters.forma_pago}
                      />
                    </Col>
                  </Grid>
                  <Grid colGap={21} rowGap={21} narrow={false}>
                    <Col desktop={12}>
                      <div className={`total-container pie`}>
                        <BarChart
                          data={{
                            labels: [
                              "Pagado",
                              "En Gestión",
                              "Promesa Pago",
                              "Derivados",
                              "Cerrados"
                            ],
                            datasets: [
                              {
                                label: "Cantidad",
                                data: companiaFormaPago,
                                backgroundColor: [
                                  `${leerColor(colores.verde)}`,
                                  `${leerColor(colores.gris)}`,
                                  `${leerColor(colores.naranja)}`,
                                  `${leerColor(colores.azul)}`,
                                  `${leerColor(colores.rojo)}`,
                                ],
                              },
                            ],
                          }}
                          optionsData={optionsPagoEmpresa}
                        />
                      </div>
                    </Col>
                  </Grid>

                  <Grid colGap={21} rowGap={21} narrow={false}>
                    <Col desktop={4}>
                      <Grid colGap={21} rowGap={21} narrow={false}>
                        <Col desktop={12}>
                          <Subtitle
                            icon={<FiList />}
                            title={`Estado de rechazos`}
                            description={
                              "Total de rechazos de todas las compañias"
                            }
                            disableDivider={true}
                          />
                        </Col>
                        <Col desktop={12}>
                          {Object.keys(estadisticasGenerales).length > 0 && (
                            <div className={`total-container pie`}>
                              <PieChart
                                data={{
                                  labels: [
                                    "Resuelto positivo",
                                    "En Gestión",
                                    "Promesa Pago",
                                    "Negativo Derivado",
                                    "Negativo Cerrado",
                                  ],
                                  datasets: [
                                    {
                                      label: "Cantidad",
                                      data: Object.values(
                                        estadisticasGenerales
                                          .estadistica_estados.data
                                      ),
                                      backgroundColor: [
                                        `${leerColor(colores.verde)}`,
                                        `${leerColor(colores.gris)}`,
                                        `${leerColor(colores.naranja)}`,
                                        `${leerColor(colores.azul)}`,
                                        `${leerColor(colores.rojo)}`,
                                      ],
                                      hoverOffset: 4,
                                    },
                                  ],
                                }}
                                optionsData={optionsPie}
                              />
                            </div>
                          )}
                        </Col>
                      </Grid>
                    </Col>
                    <Col desktop={4}>
                      <Grid colGap={21} rowGap={21} narrow={false}>
                        <Col desktop={12}>
                          <Subtitle
                            icon={<FiCheck />}
                            title={`Resueltos Positivos por medio`}
                            description={
                              "Información sobre los cobrados y los medios"
                            }
                            disableDivider={true}
                          />
                        </Col>
                        <Col desktop={12}>
                          {Object.keys(estadisticasGenerales).length > 0 && (
                            <div className={`total-container pie`}>
                              <PieChart
                                data={{
                                  labels: ["Whatsapp", "Email", "Teléfono"],
                                  datasets: [
                                    {
                                      label: "Cantidad",
                                      data: Object.values(
                                        estadisticasGenerales
                                          .estadisticas_medios_contacto_positivos
                                          .data
                                      ),
                                      backgroundColor: [
                                        `${leerColor(colores.celesteEstados)}`,
                                        `${leerColor(colores.celeste)}`,
                                        `${leerColor(colores.celestePasos)}`,
                                      ],
                                      hoverOffset: 4,
                                    },
                                  ],
                                }}
                                optionsData={optionsPie}
                              />
                            </div>
                          )}
                        </Col>
                      </Grid>
                    </Col>
                    <Col desktop={4}>
                      <Grid colGap={21} rowGap={21} narrow={false}>
                        <Col desktop={12}>
                          <Subtitle
                            icon={<FiDollarSign />}
                            title={`Importe por compañia`}
                            description={`Importes de ${
                              companias.find(
                                (item) =>
                                  parseInt(item.value) ===
                                  parseInt(filters.compania)
                              )?.label
                            }`}
                            disableDivider={true}
                          />
                        </Col>
                        {Object.keys(estadisticasImportes).length > 0 ? (
                          <Col desktop={12}>
                            {Object.keys(estadisticasImportes).length > 0 && (
                              <div className={`total-container pie`}>
                                <PieChart
                                  data={{
                                    labels: [
                                      "Resuelto positivo",
                                      "Negativo derivado",
                                      "Negativo cerrado"
                                    ],
                                    datasets: [
                                      {
                                        label: "Importe",
                                        data: Object.values(
                                          estadisticasImportes
                                        ),
                                        backgroundColor: [
                                          `${leerColor(colores.verde)}`,
                                          `${leerColor(colores.azul)}`,
                                          `${leerColor(colores.rojo)}`,
                                        ],
                                        hoverOffset: 4,
                                      },
                                    ],
                                  }}
                                  optionsData={optionsPie}
                                />
                              </div>
                            )}
                          </Col>
                        ) : null}
                      </Grid>
                    </Col>
                  </Grid>
                </>
              ) : (
                <SpinnerLoading text="Cargando gráficos" />
              )
            ) : null}

            {tabSelected === 1 ? (
              <>
                  <Subtitle
                  icon={<FiUser />}
                  title={`Analistas`}
                  description={"Información sobre el rendimiento de cada analista"}
                  disableDivider={true}
                />
                {!loading ?

                <NewTable
                  columns={tableData}
                  data={tablaAnalistas}
                  order={handleTableOrder}
                  orderParams={order}
                />
                : <SpinnerLoading text={"Cargando analistas"} />}

                <Divider />

                {informes.length > 0 || !loading ? (
                  informes.map((informe, index) => {
                    return (
                      <div>
                        <Grid colGap={21} rowGap={21} narrow={false}>
                          <Col desktop={12}>
                            <Subtitle
                              icon={<FiUser />}
                              title={`Cantidad - ${
                                informe.user.charAt(0).toUpperCase() +
                                informe.user.slice(1)
                              }`}
                              description={`Información sobre el rendimiento de ${
                                informe.user.charAt(0).toUpperCase() +
                                informe.user.slice(1)
                              }`}
                              disableDivider={true}
                            />
                          </Col>
                          {informe.tablaCantidad.length > 0 ? (
                            <Col desktop={12}>
                              <NewTable
                                columns={tableDataInforme}
                                data={informe.tablaCantidad}
                                order={handleTableOrder}
                                orderParams={order}
                              />
                            </Col>
                          ) : null}
                          <Col desktop={12}>
                            <Subtitle
                              icon={<FiUser />}
                              title={`Importe - ${
                                informe.user.charAt(0).toUpperCase() +
                                informe.user.slice(1)
                              }`}
                              description={`Información sobre el rendimiento de ${
                                informe.user.charAt(0).toUpperCase() +
                                informe.user.slice(1)
                              }`}
                              disableDivider={true}
                            />
                          </Col>
                          {informe.tablaImporte.length > 0 ? (
                            <Col desktop={12}>
                              <NewTable
                                columns={tableDataInformeImporte}
                                data={informe.tablaImporte}
                                order={handleTableOrder}
                                orderParams={order}
                              />
                            </Col>
                          ) : null}
                          {index !== informes.length - 1 ? (
                            <Col desktop={12}>
                              <Divider />
                            </Col>
                          ) : null}
                        </Grid>
                      </div>
                    );
                  })
                ) : (
                  <SpinnerLoading text={"Cargando informe por analista..."} />
                )}
              </>
            ) : null}
          </Container>
        </NewInner>
      </NewWrapper>
      </Layout>
    </div>
  );
};

export default NewEstadisticasCobranzas;
