import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../constants";

export const Container = styled.div`
  display: inline-block;
  white-space: nowrap;
  
  /* // Estados // 
  .emitido, .cerradopositivo-conforme, .cerradopositivo-conformeparcial, .cerradopositivo-disconforme, .resuelto-positivo {
    font-weight: 600;
    background-color: #edfff2;
    color: ${leerColor(colores.verde)};
    border-radius: 20px;
    padding: 6px 12px;
  }
  .notificado {
    font-weight: 600;
    background-color: #faf4ff;
    color: ${leerColor(colores.violeta)};
    border-radius: 20px;
    padding: 6px 12px;
  }
  .pendiente-emisión-sin-asignar{
    font-weight: 600;
    background-color: #fff7ed;
    color: ${leerColor(colores.naranjaEstados)};
    border-radius: 20px;
    padding: 6px 12px;
    position: relative;
    //border:1px solid ${leerColor(colores.naranjaEstados)};
  }
  .pendiente-emisión, .ingresado, .pendiente {
    font-weight: 600;
    background-color: #f6f6f6;
    color: ${leerColor(colores.grisEstados)};
    border-radius: 20px;
    padding: 6px 12px;
    position: relative;
  }
  .emisiónprovisoria, .pendientededocumentación, .pendientedepago, .pendientederesolución, .promesa-de-pago {
    font-weight: 600;
    background-color: #f0faff;
    color: ${leerColor(colores.celeste)};
    border-radius: 20px;
    padding: 6px 12px;
    text-align: left;
    width: min-content;
  }
  .pendienteaprobación, .ofrecimiento{
    font-weight: 600;
    background-color: #ecf0ff;
    color: ${leerColor(colores.azul)};
    border-radius: 20px;
    padding: 6px 12px;
    width: min-content;
  }
  .rechazado, .rechazado-sin-asignar, .cerradonegativo, .negativo-derivado, .negativo-cerrado
 {
    font-weight: 600;
    background-color: #fff6f6;
    color: ${leerColor(colores.rojo)};
    border-radius: 20px;
    padding: 6px 12px;
    //border:1px solid ${leerColor(colores.rojo)};
  }

  .emisiónprovisoria{
    color: ${leerColor(colores.celesteEstados)};
    font-weight: 700;
  }
  .pendientesuscripción{
    color: ${leerColor(colores.naranjaEstadosSuscripcion)};
    font-weight: 700;
  }
  .pendienteaprobación{
    color: ${leerColor(colores.azul)};
    font-weight: 700;
  }

  .activo{
    font-weight: 600;
    background-color: #edfff2;
    color: ${leerColor(colores.verde)};
    border-radius: 20px;
    padding: 6px 12px;
  }

  .inactivo{
    font-weight: 600;
    background-color: #f6f6f6;
    color: ${leerColor(colores.grisEstados)};
    border-radius: 20px;
    padding: 6px 12px;
  }

  .estado_previo_rechazado {
    position: absolute;
    top: -15px;
    right: 0;
    //background: #fff7ed;
    background: ${leerColor(colores.blanco)};
    border-radius: 100px;
    color: hsl(36.10000000000002,100%,66.1%);
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    border: 1px solid hsl(36.1deg 100% 82.49%);
  }

      // Estados //
      .emisión-sin-curso, .emitido---cambio-pas, .endoso-enviado-a-compañia {
      font-weight: 600;
      background-color: #e1f5ff;
      color: ${leerColor(colores.celeste)};
      border-radius: 20px;
      padding: 6px 12px;
      display: block;
    } */

      .prioridad {
    background-color: ${leerColor(colores.negro)};
    color: ${leerColor(colores.blanco)};
    width: fit-content;
    border-radius: 8px;
    padding: 4px 10px;
    box-sizing: border-box;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 10.5px;
    position: absolute;
    right: -5px;
    top: -17px;
  }

    // Estados //
    .emisión-sin-curso, .emitido---cambio-pas, .endoso-enviado-a-compañia {
      font-weight: 600;
      background-color: ${({ theme }) => theme.celeste_fondo};
      color: ${({ theme }) => theme.celeste_texto};
      border-radius: 20px;
      padding: 6px 12px;
      display: block;
      transition: all 0.5s;
    }

  // Estados //
  .emitido,
  .cerradopositivo,
  .cerradopositivo-conformeparcial,
  .cerradopositivo-conforme,
  .cerradopositivo-disconforme,
  .resueltopositivo,
  .resuelto-positivo,
  .emitido-certificado {
    font-weight: 600;
    background-color: ${({ theme }) => theme.verde_fondo};
    color: ${({ theme }) => theme.verde_texto};
    border-radius: 20px;
    padding: 6px 12px;
    width: min-content;
    display: block;
    transition: all 0.5s;
  }

  .veces_notificado {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 600;
    font-size: 12px;
    background-color: #EEE;
    color: #222;
    border-radius: 20px;
    padding: 6px 12px;
    width: min-content;
  }

  .pendiente-emisión-sin-asignar {
    /*
    font-weight: 600;
    background-color: #fff7ed;
    color: ${leerColor(colores.naranjaEstados)};
    border-radius: 20px;
    padding: 6px 12px;
    position: relative;
    //border:1px solid ${leerColor(colores.naranjaEstados)};
    width: min-content;
    */

    display: flex !important;
    font-weight: 600 !important;
    background-color: ${({ theme }) => theme.naranja_fondo};
    color: ${leerColor(colores.naranjaEstados)};
    border-radius: 20px !important;
    padding: 6px 12px !important;
    position: relative !important;
    width: fit-content !important;
    align-items: center !important;
    transition: all 0.5s;
  }
  .pendiente,
  .ingresado,
  .pendiente-emisión {
    display: flex !important;
    font-weight: 600 !important;
    background-color: ${({ theme }) => theme.gris_fondo} !important;
    color: ${({ theme }) => theme.gris_texto} !important;
    border-radius: 20px !important;
    padding: 6px 12px !important;
    position: relative !important;
    width: fit-content !important;
    align-items: center !important;
  }
  .rechazado,
  .rechazado-sin-asignar,
  .negativoderivado,
  .negativo-derivado,
  .negativo-cerrado,
  .cerradonegativo {
    font-weight: 600;
    background-color: ${({ theme }) => theme.rojo_fondo};
    color: ${leerColor(colores.rojo)};
    border-radius: 20px;
    padding: 6px 12px;
    //border:1px solid ${leerColor(colores.rojo)};
    width: min-content;
    display: block;
    transition: all 0.5s;
  }

    
  .emisiónprovisoria, .pendientededocumentación, .pendientedepago, .pendientederesolución, .promesa-de-pago {
    font-weight: 600;
    background-color: #f0faff;
    color: ${leerColor(colores.celeste)};
    border-radius: 20px;
    padding: 6px 12px;
    text-align: left;
    width: min-content;
    display: block;
  }
  .pendientesuscripción {
    color: ${leerColor(colores.naranjaEstadosSuscripcion)};
    font-weight: 700;
    display: block;
  }
  .pendienteaprobación, .ofrecimiento, .notificado, .contactado{
    font-weight: 500;
    background-color: #ecf0ff;
    color: ${leerColor(colores.azul)};
    border-radius: 20px;
    padding: 6px 12px;
    width: min-content;
    display: block;
  }
  .estado_previo_rechazado {
    position: absolute;
    top: -15px;
    right: 0;
    //background: #fff7ed;
    background: ${leerColor(colores.blanco)};
    border-radius: 100px;
    color: hsl(36.10000000000002,100%,66.1%);
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    border: 1px solid hsl(36.1deg 100% 82.49%);
  }

  .promesa-de-pago {
    font-weight: 600;
    background-color: ${({ theme }) => theme.naranja_fondo};
    color: ${leerColor(colores.naranjaEstados)};
    border-radius: 20px;
    padding: 6px 12px;
    text-align: left;
    width: min-content;
    display: block;
  }

  .actionsContainer {
    display: flex;
    gap: 16px;
    box-sizing: border-box;
     
    a { 
      text-decoration: none;
    }
  }

  .actions-center {
    justify-content: center;
  }

  .container-flex {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .activo{
    font-weight: 600;
    background-color: ${({ theme }) => theme.verde_fondo};
    color: ${({ theme }) => theme.verde_texto};
    border-radius: 20px;
    padding: 6px 12px;
  }

  .inactivo{
    font-weight: 600;
    background-color: ${({ theme }) => theme.gris_fondo};
    color: ${({ theme }) => theme.gris_texto};
    border-radius: 20px;
    padding: 6px 12px;
  }

  .notificado, .contactado {
    background-color: #faf4ff;
    color: ${leerColor(colores.violeta)};
  }
`

