import { meses } from "../../constants/data";
import { Day } from "./styled";
import SpinnerLoading from "../SpinnerLoading";
import dayjs from "dayjs"

const NewCalendar = ({ data }) => { 
  const { dia, mes, anio, calendario, handleDiaFichaje } = data
  const firstDay = new Date(anio, mes, 1).getDay() + 1

  return (
    <div className="calendar-container">
      <div className="calendario-header">
        <div className="calendario-header-titulo">
          <p>Mes seleccionado</p>
          <h4 className="subtitulo-historial">
            {meses.find((item) => mes == item.value)?.label}
          </h4>
        </div>
        <div className="calendario-header-referencias">
          <div className="referencia-container">
            <div className="referencia"></div>
            <span>Excepciones</span>
          </div>
        </div>
      </div>
      <div className="grid-calendar-header">
        <span>Dom</span>
        <span>Lun</span>
        <span>Mar</span>
        <span>Mie</span>
        <span>Jue</span>
        <span>Vie</span>
        <span>Sab</span>
      </div>

      <div className="grid-calendar">
        {calendario.length > 0 ? (
          calendario.map((item, index) => {
            if (index === 0) {
              return (
                <Day
                  startPosition={firstDay}
                  onClick={() => handleDiaFichaje(item.dia)}
                >
                  {item.dia}
                </Day>
              );
            }
            return (
              <Day
                onClick={() => handleDiaFichaje(item.dia)}
                className={`${
                  dayjs(dia).format("D") == item.dia && "selected"
                } ${item.excepcion.length > 0 ? "excepcion" : ""}`}
              >
                {item.dia}
              </Day>
            );
          })
        ) : (
          <SpinnerLoading />
        )}
      </div>
    </div>
  );
};

export default NewCalendar;
