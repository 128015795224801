// Components
import Modal from "../../../Modal";
import { Grid, Col } from "../../../Grid";
import NewInputTextarea from "../../../NewInputTextarea";
// Hooks
import { useForm } from "../../../../hooks/useForm";
// Utils
import { gestionarRechazoReglas, modalRechazoReglas } from "../../../../utils/formRules";
import { gestionarRechazoForm, modalRechazoForm } from "../../../../utils/formInitialValues";
// Services
import { useRechazo } from "../../../../hooks/useRechazo";
import useModal from "../../../../hooks/useModal";
import { useLoading } from "../../../../hooks/useLoading";
import SpinnerLoading from "../../../SpinnerLoading";
import ModalSuccess from "../../../ModalSuccess";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import { RechazoContainer } from "./styled";
import ErrorInput from "../../../ErrorInput";
import Success from "../../../Success";
import Divider from "../../../Divider";
import NewInputSelect from "../../../NewInputSelect";
import InputFile from "../../../InputFile";
import { updateReject } from "../../../../services/Rechazos";
import { useEffect, useState } from "react";

const ModalAsignarRechazo = ({ modal, rechazo, userInfo, asignOperador,getHistorial,getRechazo, selectOperadores}) => {
  const [operadorAsignado, setOperadorAsignado] = useState(null);
  const { isModalOpen, handleModal } = modal;
  const [success, setSuccess] = useState(false)
  const { loading, setLoading } = useLoading()
  const [ rechazosToAsign, setRechazosToAsign ] = useState([])


  const closeModal = () => {
    setSuccess(false)
    handleModal()
  }

  const asign = async (operadorAsignado, rechazosToAsign) => {
    setLoading(true);
    try {
      const res = await asignOperador(
        rechazosToAsign,
        operadorAsignado,
        userInfo.id_usuario 
      )
      setSuccess(true);
      getHistorial();
      getRechazo();
    } catch (e){
      console.log(e)
    } finally {
      setLoading(false);
    }
  }

  useEffect(()=>{
    if ( rechazo ) {
      setRechazosToAsign([rechazo])
    }
  },[rechazo])

  return (
    <>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
            <NewTitle>Gestionar rechazo</NewTitle>
            <p>Modificá los detalles del rechazo</p>
          </div>
          <Divider />
          {!success ?
          <>
            <div className="data-container">
              <Grid colGap={21} rowGap={21} narrow={true}>
                <Col desktop={6}>
                  <NewInputSelect
                    type="number"
                    labelName="Operadores Disponibles"
                    name="estado"
                    placeholder="Sin Asignar"
                    options={selectOperadores}
                    onChange={e=>setOperadorAsignado(e.target.value)}
                  />
                </Col>
              </Grid>
            </div>
            <Divider />
            <div className={"actionButtonsCont"}>
              <div className={"buttonsCont"}>
                <NewButton
                  onClick={handleModal}
                  backgroundColor={false}
                  borderColor={true}
                  textColor={true}
                  icon={false}
                >
                  Cancelar
                </NewButton>
                {
                  operadorAsignado &&  rechazosToAsign.length > 0 &&
                  <NewButton
                    backgroundColor={true}
                    borderColor={false}
                    textColor={false}
                    icon={false}
                    onClick={()=>asign(operadorAsignado, rechazosToAsign)}
                    disabled={loading}
                  >
                    {loading ? "Asginando..." : "Asignar"}
                  </NewButton>
                }
              </div>
            </div>
          </>
          :
          <Success title="Asignación realizada!" description="El rechazo fue gestionado correctamente" handleModal={closeModal} />
          }
        </RechazoContainer>
      </Modal>
    </>
  );
};

export default ModalAsignarRechazo;
