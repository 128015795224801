import React from "react";
import VendedorEdicionPremiosTab from "../../Vendedor/EdicionPremios";

const PremiosEdicionTab = ({ data }) => {
  const { premios, mes, anio, loading } = data;
  
  return (
    <VendedorEdicionPremiosTab data={{ premios, mes, anio, loading }} />
  );
};

export default PremiosEdicionTab;

